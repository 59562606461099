import { injectable } from 'tsyringe';
import SiteChemicalRepository, { GetSiteChemicalsFilters, GetWorkingSiteChemicalsFilters } from '../../domain/repositories/siteChemicalRepository';
import { mapSiteChemical, SiteChemical } from '../../domain/entities/siteChemical';
import Evaluation from '../../domain/entities/evaluation';
import { SiteResourceDocument } from '../../domain/entities/document';
import { ResourceEvaluationState } from '../../domain/entities/resourceEvaluationState.enum';
import Requirement from '../../domain/entities/requirement';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import { GetSiteChemicalsResponse } from '../responses/siteChemicals';
import { GetEvaluationsResponse, mapEvaluation } from '../responses/evaluations';
import { ApiService } from '../utilities/apiService';
import { GetResourceEvaluationFilter } from '../../domain/repositories/siteRepository';
import { NewResource } from '../../presentation/hooks/Site/useSiteResourcesViewModel';
import { GetDocumentsFilter } from '../../domain/repositories/documentRepository';

@injectable()
class ServerSiteChemicalRepository implements SiteChemicalRepository {
	constructor(private apiService: ApiService) { }

	private getURL = (companyId: string, siteId: string, chemicalId?: string): string => {
		return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/chemicals${chemicalId ? `/${chemicalId}` : ''}`;
	};

	// Public.
	async getSiteChemicals(
		companyId: string,
		siteId: string,
		page: number,
		perPage: number,
		archived?: boolean,
		filter?: GetSiteChemicalsFilters,
		sort?: SortMeta,
		supplierId?: string,
	): Promise<PaginatedResults<SiteChemical>> {
		const { variantIds, specializationIds, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: perPage.toString(),
			...(supplierId !== undefined && supplierId !== null ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...sort,
		});

		if (archived === true) {
			params.append('archived', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/chemicals?${params.toString()}`,
		);
		const data: GetSiteChemicalsResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteChemical(siteId)),
		};
	}

	// Public.
	async getWorkingSiteChemicals(
		companyId: string,
		siteId: string,
		page: number,
		perPage: number,
		archived?: boolean,
		filter?: GetWorkingSiteChemicalsFilters,
		sort?: SortMeta,
	): Promise<PaginatedResults<SiteChemical>> {
		const { variantIds, specializationIds, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: perPage.toString(),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...sort,
		});

		if (archived === true) {
			params.append('archived', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${companyId}/chemicals?${params.toString()}`,
		);
		const data: GetSiteChemicalsResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteChemical(siteId)),
		};
	}

	// Public.
	async getSiteChemical(companyId: string, siteId: string, chemicalId: string): Promise<SiteChemical | undefined> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, chemicalId)}`);
		if (!response.ok) {
			return undefined;
		}
		return response.json();
	}

	async createSiteChemical(
		companyId: string,
		siteId: string,
		resources: NewResource[],
		selectAll: boolean,
		selectedResourceNumber: number,
		selectAllVariants: string,
		selectAllSpecializations: string[],
		filterSiteResources?: Record<string, string | string[]>,
	): Promise<boolean> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId)}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				resources,
				selectAll,
				selectedResourceNumber,
				selectAllVariant: selectAllVariants,
				selectAllSpecializations,
				filterSiteResources,
			}),
		});
		return Promise.resolve(true);
	}

	async deleteSiteChemical(companyId: string, siteId: string, chemicalId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, chemicalId)}`, {
			method: 'DELETE',
		});
	}

	async restoreSiteChemical(companyId: string, siteId: string, chemicalId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, chemicalId)}/restore`, {
			method: 'PUT',
		});
	}

	async updateSiteChemicalVariant(companyId: string, siteId: string, chemicalId: string, variantId?: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, chemicalId)}/variants`, {
			method: 'POST',
			body: variantId ? JSON.stringify({ variantId: variantId }) : null,
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	async updateSiteChemicalSpecializations(companyId: string, siteId: string, chemicalId: string, specializationIds: string[]): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, chemicalId)}/specializations`, {
			method: 'PUT',
			body: JSON.stringify({ specializationsIds: specializationIds }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	async getSiteChemicalEvaluations(
		companyId: string,
		siteId: string,
		chemicalId: string,
		pageParam: number,
		sort?: SortMeta,
		filter?: GetResourceEvaluationFilter,
	): Promise<Evaluation<ResourceEvaluationState>[]> {
		const query = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...sort,
			...filter,
		}).toString();

		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, chemicalId)}/evaluations?${query}`);
		const { results }: GetEvaluationsResponse<ResourceEvaluationState> = await response.json();

		return results.map(mapEvaluation);
	}

	async evaluateSiteChemical(
		companyId: string,
		siteId: string,
		chemicalId: string,
		value: ResourceEvaluationState,
	): Promise<Evaluation<ResourceEvaluationState>> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, chemicalId)}/evaluations`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ value }),
		});
		return response.json();
	}

	async autoEvaluateSiteChemical(companyId: string, siteId: string, chemicalId: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, chemicalId)}/evaluate`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw new Error(`Failed to auto evaluate site chemical: ${response.status}`);
		}
	}

	async getSiteChemicalDocuments(
		companyId: string,
		siteId: string,
		chemicalId: string,
		filter: GetDocumentsFilter,
		sort: SortMeta,
		pageParam: number,
	): Promise<SiteResourceDocument[]> {
		const { tags, expiresAt, ...restFilter } = filter ?? {};

		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...(tags ? { tags: tags.join(',') } : {}),
			...sort,
		});

		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, chemicalId)}/requirements?${params.toString()}`);
		const requirements = await response.json();
		return requirements['results'] ?? [];
	}

	async addRequirementToSiteChemical(
		companyId: string,
		siteId: string,
		chemicalId: string,
		requirements: {
			documentTypeId: string;
			isOptional: boolean;
			graceDays: number;
		}[],
	): Promise<Requirement> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, chemicalId)}/requirements`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ requirements }),
		});
		return response.json();
	}

	async updateSiteChemicalGlobalStatus(companyId: string, siteId: string, chemicalId: string, isGlobal: boolean): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, chemicalId)}/global`, {
			method: 'PUT',
			body: JSON.stringify({ isGlobal }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw new Error(`Failed to update site chemical global status: ${response.status}`);
		}
		const { hasRequirements = true } = await response.json();
		return hasRequirements;
	}
}

export default ServerSiteChemicalRepository;
