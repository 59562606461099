import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import FileEntity from "../../../domain/entities/file";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import Site from "../../../domain/entities/site";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { UpdateFilter } from "../Site/useSiteResourceDetailViewModel";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import AiTask, { AiTaskStatus } from "../../../domain/entities/aiTask";
import { ConfirmTaskParams } from "../../components/Views/Documents/ManageTaskWithAiModal";
import AiTaskDocument from "../../../domain/entities/aiTaskDocument";
import { useState } from "react";
import DocumentType from "../../../domain/entities/documentType";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";

export interface DocumentAIViewModel {
  //AI section
  createAiTask(
    companyId: string,
    files: FileEntity,
    sectionCode: AiTaskSectionCode,
    resourceId?: string,
    type?: DocumentTypeCategory,
    siteId?: string,
    isSupplier?: boolean
  ): Promise<string>;
  getAiTasks(
    companyId: string,
    sectionCode: AiTaskSectionCode,
    resourceType?: DocumentTypeCategory,
    resourceId?: string,
    siteId?: string,
    supplierId?: string
  ): Promise<AiTask[]>;
  getAiTaskDetail(companyId: string, taskId: string): Promise<AiTask>;
  deleteAiTask(companyId: string, taskId: string): Promise<void>;
  sendTaskConfirmation(
    companyId: string,
    taskId: string,
    aiTaskDocuments: AiTaskDocument[],
    siteIds: string[]
  ): Promise<void>;
  getAiTaskSites(
    companyId: string,
    sectionCode: AiTaskSectionCode,
    taskId: string,
    sortSites: SortMeta,
    filterSites: GetSitesFilter,
    pageParam?: number
  ): Promise<Site[]>;
  getDocumentTypesForAi(
    companyId: string,
    aiTaskid: string,
    resourceType: DocumentTypeCategory
  ): Promise<DocumentType[]>;
}

export type UseDocumentAI = {
  setAiTaskId: (aiTaskId: string) => void;
  aiTasks: AiTask[];
  aiTasksHasNextPage: boolean;
  aiTasksFetchNextPage: () => void;
  aiTasksFetching: boolean;
  aiTasksRefetch: () => void;
  aiTaskDetail: AiTask;
  aiTaskDetailFetching: boolean;
  createAiTask: (
    files: FileEntity,
    sectionCode: AiTaskSectionCode,
    resourceId?: string,
    type?: DocumentTypeCategory,
    siteId?: string
  ) => void;
  deleteAiTask: (taskId: string) => void;
  confirmAiTask: (params: ConfirmTaskParams) => void;
  confirmAitaskIsLoading: boolean;
  sitesForAiTask: Site[];
  filterAiSites: GetSitesFilter;
  updateFilterAiSites: UpdateFilter;
  setSortAiSites: (sort: SortMeta) => void;
  sortAiSites: SortMeta;

  documentTypesForAi: DocumentType[];
  setEnableDocumentTypesForAiQuery: (enable: boolean) => void;
};

function useDocumentAI(
  viewModel: DocumentAIViewModel,
  siteId?: string,
  resourceId?: string,
  resourceType?: DocumentTypeCategory,
  hookSectionCode?: AiTaskSectionCode,
  isSupplier?: boolean
): UseDocumentAI {
  const { companyId } = useAuth();

  const [aiTaskId, setAiTaskId] = useState<string>(undefined);
  const [filterAiSites, setFilterAiSites] = useState<GetSitesFilter>();
  const [sortAiSites, setSortAiSites] = useState<SortMeta>();
  const [enableDocumentTypesForAiQuery, setEnableDocumentTypesForAiQuery] =
    useState(false);

  const isSite = location.pathname.includes("sites");

  // AI section

  const createAiTaskMutation = useMutation(
    (params: {
      files: FileEntity;
      sectionCode: AiTaskSectionCode;
      type?: DocumentTypeCategory;
    }) => {
      return viewModel.createAiTask(
        companyId,
        params.files,
        params.sectionCode,
        resourceId,
        params.type,
        siteId,
        isSupplier
      );
    },
    {
      onSuccess: () => getAiTasksQuery.refetch(),
      onError: (err) => console.error(err),
    }
  );

  const createAiTask = async (
    files: FileEntity,
    sectionCode: AiTaskSectionCode
  ) => {
    return await createAiTaskMutation.mutateAsync({
      files,
      sectionCode,
      type: resourceType
    });
  };


  const getAiTasksQuery = useInfiniteQuery(
    [
      "get-ai-tasks" + hookSectionCode,
      companyId,
      hookSectionCode,
      resourceType,
      resourceId,
      siteId,
    ],
    async ({ pageParam = 1 }) => {
      const results = await viewModel.getAiTasks(
        companyId,
        hookSectionCode,
        resourceType,
        resourceId,
        siteId,
        isSupplier ? resourceId : undefined
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      refetchInterval: (data) => {
        const tasks = data?.pages?.flat();

        const shouldPoll = tasks?.some(
          (res) =>
            res.status !== AiTaskStatus.TERMINATED &&
            res.status !== AiTaskStatus.ERROR
        );

        return shouldPoll ? 5000 : false;
      },
      retry: false,
      enabled: hookSectionCode !== undefined,
    }
  );

  const {
    data: aiTaskDetail,
    isLoading: aiTaskDetailIsFetching,
    refetch: getAiTaskDetail,
  } = useQuery<AiTask, Error>(
    ["aiTaskDetail", companyId, aiTaskId],
    async () => {
      if (aiTaskId) {
        return await viewModel.getAiTaskDetail(companyId, aiTaskId);
      } else {
        return null; // or any other appropriate value
      }
    },
    {
      retry: false,
      initialData: undefined,
    }
  );

  const deleteAiTaskMutation = useMutation(
    (taskId: string) => {
      return viewModel.deleteAiTask(companyId, taskId);
    },
    {
      onSuccess: () => getAiTasksQuery.refetch(),
      onError: (err) => console.error(err),
    }
  );

  const confirmAiTaskMutation = useMutation(
    ({ taskId, aiTaskDocuments, siteIds }: ConfirmTaskParams) => {
      return viewModel.sendTaskConfirmation(
        companyId,
        taskId,
        aiTaskDocuments,
        siteIds
      );
    },
    {
      onSuccess: () => getAiTasksQuery.refetch(),
      onError: (err) => console.error(err),
    }
  );

  const getSitesForAiTask = useInfiniteQuery(
    ["ai-tasks-sites", companyId, aiTaskId, sortAiSites, filterAiSites],
    async ({ pageParam = 1 }) => {
      return await viewModel.getAiTaskSites(
        companyId,
        hookSectionCode,
        aiTaskId,
        sortAiSites,
        filterAiSites,
        pageParam
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: aiTaskId !== undefined && !isSite,
    }
  );

  const documentTypesForAiQuery = useQuery<DocumentType[], Error>(
    ["documentTypeForAi", companyId, aiTaskId],
    async () =>
      await viewModel.getDocumentTypesForAi(companyId, aiTaskId, resourceType),
    {
      initialData: [],
      enabled: enableDocumentTypesForAiQuery,
    }
  );

  const aiTasks = getAiTasksQuery.data?.pages?.flat() ?? [];
  const sitesForAiTask = getSitesForAiTask.data?.pages.flat() ?? [];

  return {
    setAiTaskId,
    aiTasks,
    aiTasksHasNextPage: getAiTasksQuery.hasNextPage,
    aiTasksFetchNextPage: getAiTasksQuery.fetchNextPage,
    aiTasksFetching: getAiTasksQuery.isLoading,
    aiTasksRefetch: getAiTasksQuery.refetch,
    aiTaskDetail,
    aiTaskDetailFetching: aiTaskDetailIsFetching,
    createAiTask,
    deleteAiTask: deleteAiTaskMutation.mutateAsync,
    confirmAiTask: confirmAiTaskMutation.mutateAsync,
    confirmAitaskIsLoading: confirmAiTaskMutation.isLoading,
    sitesForAiTask,
    filterAiSites,
    updateFilterAiSites: (field, value) => {
      return setFilterAiSites({ ...filterAiSites, [field]: value });
    },
    setSortAiSites,
    sortAiSites,

    documentTypesForAi: documentTypesForAiQuery.data,
    setEnableDocumentTypesForAiQuery,
  };
}

export default useDocumentAI;
