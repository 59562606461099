import DeleteSiteSupplierRequirement from '../../../../domain/interactors/requirement/deleteSiteSupplierRequirement';
import AddCompanySupplierRequirement from '../../../../domain/interactors/supplier/addCompanySupplierRequirement';
import AddCompanySupplierRequirementSpecialization from '../../../../domain/interactors/supplier/addCompanySupplierRequirementSpecialization';
import AddCompanySupplierRequirementVariant from '../../../../domain/interactors/supplier/addCompanySupplierRequirementVariant';
import AddRequirementToSupplier from '../../../../domain/interactors/supplier/addRequirementToSupplier';
import AddRequirementToSupplierCompanyResource from '../../../../domain/interactors/supplier/addRequirementToSupplierCompanyResource';
import AutoEvaluateCompanySupplierResource from '../../../../domain/interactors/supplier/autoEvaluateCompanySupplierResource';
import AutoEvaluateSupplier from '../../../../domain/interactors/supplier/autoEvaluateSupplier';
import CheckSupplierHasRequirements from '../../../../domain/interactors/supplier/checkSupplierHasRequirements';
import CheckSupplierResourceHasRequirements from '../../../../domain/interactors/supplier/checkSupplierResourceHasRequirements';
import CreateSupplierDocument from '../../../../domain/interactors/supplier/createSupplierDocument';
import CreateSupplierResourceDocument from '../../../../domain/interactors/supplier/createSupplierResourceDocument';
import DeleteCompanySupplierRequirement from '../../../../domain/interactors/supplier/deleteCompanySupplierRequirement';
import DeleteSubcontractor from '../../../../domain/interactors/supplier/deleteSubcontractor';
import DeleteSupplier from '../../../../domain/interactors/supplier/deleteSupplier';
import EvaluateCompanySupplierRequirement from '../../../../domain/interactors/supplier/evaluateCompanySupplierRequirement';
import EvaluateCompanySupplierResource from '../../../../domain/interactors/supplier/evaluateCompanySupplierResource';
import EvaluateCompanySupplierResourceRequirement from '../../../../domain/interactors/supplier/evaluateCompanySupplierResourceRequirement';
import EvaluateSupplier from '../../../../domain/interactors/supplier/evaluateSupplier';
// import EvaluateSupplierDocument from '../../../../domain/interactors/supplier/evaluateSupplierDocument';
import GetActiveSubcontractors from '../../../../domain/interactors/supplier/getActiveSubcontractors';
import GetActiveSuppliers from '../../../../domain/interactors/supplier/getActiveSuppliers';
import GetAvailableSuppliers from '../../../../domain/interactors/supplier/getAvailableSupplier';
import GetCompanySupplier from '../../../../domain/interactors/supplier/getCompanySupplier';
import GetCompanySupplierChemical from '../../../../domain/interactors/supplier/getCompanySupplierChemical';
import GetCompanySupplierChemicals from '../../../../domain/interactors/supplier/getCompanySupplierChemicals';
import GetCompanySupplierMachine from '../../../../domain/interactors/supplier/getCompanySupplierMachine';
import GetCompanySupplierMachines from '../../../../domain/interactors/supplier/getCompanySupplierMachines';
import GetCompanySupplierRequirements from '../../../../domain/interactors/supplier/getCompanySupplierRequirements';
import GetCompanySupplierResourceEvaluations from '../../../../domain/interactors/supplier/getCompanySupplierResourceEvaluations';
import GetCompanySupplierTool from '../../../../domain/interactors/supplier/getCompanySupplierTool';
import GetCompanySupplierTools from '../../../../domain/interactors/supplier/getCompanySupplierTools';
import GetCompanySupplierVehicle from '../../../../domain/interactors/supplier/getCompanySupplierVehicle';
import GetCompanySupplierVehicles from '../../../../domain/interactors/supplier/getCompanySupplierVehicles';
import GetCompanySupplierWorker from '../../../../domain/interactors/supplier/getCompanySupplierWorker';
import GetCompanySupplierWorkers from '../../../../domain/interactors/supplier/getCompanySupplierWorkers';
import GetCompanySuppliers from '../../../../domain/interactors/supplier/getCompanySuppliers';
import GetInvitedSuppliers from '../../../../domain/interactors/supplier/getInvitedSuppliers';
import GetSpecializationsForCompanySupplierRequirements from '../../../../domain/interactors/supplier/getSpecializationsForCompanySupplierRequirements';
import GetSupplierByCompany from '../../../../domain/interactors/supplier/getSupplierByCompany';
import GetSupplierDetail from '../../../../domain/interactors/supplier/getSupplierDetail';
import GetSupplierDocumentEvaluation from '../../../../domain/interactors/supplier/getSupplierDocumentEvaluation';
import GetSupplierDocuments from '../../../../domain/interactors/supplier/getSupplierDocuments';
import GetSupplierEvaluations from '../../../../domain/interactors/supplier/getSupplierEvaluations';
import GetSupplierResourceDocuments from '../../../../domain/interactors/supplier/getSupplierResourceDocuments';
import GetVariantsForCompanySupplierRequirements from '../../../../domain/interactors/supplier/getVariantsForCompanySupplierRequirements';
import InviteSubcontractors from '../../../../domain/interactors/supplier/inviteSubcontrators';
import InviteSuppliers from '../../../../domain/interactors/supplier/inviteSuppliers';
import RemoveCompanySupplierRequirementSpecialization from '../../../../domain/interactors/supplier/removeCompanySupplierRequirementSpecialization';
import RemoveCompanySupplierRequirementVariant from '../../../../domain/interactors/supplier/removeCompanySupplierRequirementVariant';
import SetSupplierStatus from '../../../../domain/interactors/supplier/setStatus';
import SetSupplierRequirements from '../../../../domain/interactors/supplier/setSupplierRequirments';
import SetSupplierResourceRequirements from '../../../../domain/interactors/supplier/setSupplierResourceRequirments';
import UpdateCompanySupplierRequirement from '../../../../domain/interactors/supplier/updateCompanySupplierRequirement';
import UpdateCompanySupplierRequirements from '../../../../domain/interactors/supplier/updateCompanySupplierRequirements';
import UpdateCompanySupplierResourceSpecializations from '../../../../domain/interactors/supplier/updateCompanySupplierResourceSpecializations';
import UpdateCompanySupplierResourceVariant from '../../../../domain/interactors/supplier/updateCompanySupplierResourceVariant';
import UpdateCompanySupplierVariant from '../../../../domain/interactors/supplier/updateCompanySupplierVariant';
import UpdateSiteSupplierGlobalStatus from '../../../../domain/interactors/supplier/updateSiteSupplierGlobalStatus';
import UpdateSupplierDocument from '../../../../domain/interactors/supplier/updateSupplierDocument';
import UpdateSupplierResourceDocument from '../../../../domain/interactors/supplier/updateSupplierResourceDocument';
import UpdateSupplierVariant from '../../../../domain/interactors/supplier/updateSupplierVariant';
import { makeRequirementRepository } from '../requirements/repositories';
import { makeSupplierRepository } from './repositories';
import GetSupplierResourceBadge from '../../../../domain/interactors/supplier/getSupplierResourceBadge';
import GetAvailableBadges from '../../../../domain/interactors/resources/workers/getAvailableBadges';
import LinkBadgesResource from '../../../../domain/interactors/resources/vehicles/linkBadgesResource';
import EvaluateSupplierDocument from "../../../../domain/interactors/supplier/evaluateSupplierDocument";

export const makeGetWorkingSites = () => {
	return new GetSupplierByCompany(makeSupplierRepository());
};

export const makeGetActiveSubcontractors = () => {
	return new GetActiveSubcontractors(makeSupplierRepository());
};

export const makeDeleteSubcontractor = () => {
	return new DeleteSubcontractor(makeSupplierRepository());
};

export const makeInviteSuppliers = () => {
	return new InviteSuppliers(makeSupplierRepository());
};

export const makeInviteSubcontractors = () => {
	return new InviteSubcontractors(makeSupplierRepository());
};

export const makeDeleteSupplier = () => {
	return new DeleteSupplier(makeSupplierRepository());
};

export const makeGetAvailableSupplier = () => {
	return new GetAvailableSuppliers(makeSupplierRepository());
};

export const makeGetActiveSupplier = () => {
	return new GetActiveSuppliers(makeSupplierRepository());
};
export const makeGetInvitedSupplier = () => {
	return new GetInvitedSuppliers(makeSupplierRepository());
};
export const makeGetSupplierDetail = () => {
	return new GetSupplierDetail(makeSupplierRepository());
};

export const makeLinkBadgesResource = () => {
	return new LinkBadgesResource(makeSupplierRepository());
};

export const makeGetAvailableBadgesUseCase = () => {
	return new GetAvailableBadges(makeSupplierRepository());
};

export const makeGetSupplierEvaluations = () => {
	return new GetSupplierEvaluations(makeSupplierRepository());
};

export const makeEvaluateSupplier = () => {
	return new EvaluateSupplier(makeSupplierRepository());
};

export const makeAutoEvaluateSupplier = () => {
	return new AutoEvaluateSupplier(makeSupplierRepository());
};

export const makeUpdateSupplierVariant = () => {
	return new UpdateSupplierVariant(makeSupplierRepository());
};

export const makeAddRequirementToSupplier = () => {
	return new AddRequirementToSupplier(makeSupplierRepository());
};

export const makeSetSupplierStatus = () => {
	return new SetSupplierStatus(makeSupplierRepository());
};

export const makeGetCompanySuppliers = () => {
	return new GetCompanySuppliers(makeSupplierRepository());
};

export const makeGetCompanySupplier = () => {
	return new GetCompanySupplier(makeSupplierRepository());
};

export const makeCheckSupplierHasRequirements = () => {
	return new CheckSupplierHasRequirements(makeSupplierRepository());
};

export const makeCheckSupplierResourceHasRequirements = () => {
	return new CheckSupplierResourceHasRequirements(makeSupplierRepository());
};

export const makeSetSupplierRequirements = () => {
	return new SetSupplierRequirements(makeSupplierRepository());
};

export const makeSetSupplierResourceRequirements = () => {
	return new SetSupplierResourceRequirements(makeSupplierRepository());
};

export const makeGetSupplierDocuments = () => {
	return new GetSupplierDocuments(makeSupplierRepository());
};

export const makeCreateSupplierDocuments = () => {
	return new CreateSupplierDocument(makeSupplierRepository());
};

export const makeUpdateSupplierDocument = () => {
	return new UpdateSupplierDocument(makeSupplierRepository());
};

export const makeUpdateCompanySupplierVariant = () => {
	return new UpdateCompanySupplierVariant(makeSupplierRepository());
};

export const makeEvaluateCompanySupplierRequirement = () => {
	return new EvaluateCompanySupplierRequirement(makeSupplierRepository());
};

export const makeGetSupplierDocumentEvaluations = () => {
	return new GetSupplierDocumentEvaluation(makeSupplierRepository());
};

export const makeGetCompanySupplierWorkers = () => {
	return new GetCompanySupplierWorkers(makeSupplierRepository());
};

export const makeGetCompanySupplierMachines = () => {
	return new GetCompanySupplierMachines(makeSupplierRepository());
};

export const makeGetCompanySupplierVehicles = () => {
	return new GetCompanySupplierVehicles(makeSupplierRepository());
};

export const makeGetCompanySupplierTools = () => {
	return new GetCompanySupplierTools(makeSupplierRepository());
};

export const makeGetCompanySupplierChemicals = () => {
	return new GetCompanySupplierChemicals(makeSupplierRepository());
};

export const makeGetVariantsForCompanySupplierRequirements = () => {
	return new GetVariantsForCompanySupplierRequirements(makeSupplierRepository());
};

export const makeGetSpecializationsForCompanySupplierRequirements = () => {
	return new GetSpecializationsForCompanySupplierRequirements(makeSupplierRepository());
};

export const makeGetCompanySupplierWorker = () => {
	return new GetCompanySupplierWorker(makeSupplierRepository());
};

export const makeGetCompanySupplierMachine = () => {
	return new GetCompanySupplierMachine(makeSupplierRepository());
};

export const makeGetCompanySupplierVehicle = () => {
	return new GetCompanySupplierVehicle(makeSupplierRepository());
};

export const makeGetCompanySupplierTool = () => {
	return new GetCompanySupplierTool(makeSupplierRepository());
};

export const makeGetCompanySupplierChemical = () => {
	return new GetCompanySupplierChemical(makeSupplierRepository());
};

export const makeGetSupplierResourceDocuments = () => {
	return new GetSupplierResourceDocuments(makeSupplierRepository());
};

export const makeUpdateCompanySupplierResourceVariant = () => {
	return new UpdateCompanySupplierResourceVariant(makeSupplierRepository());
};

export const makeUpdateCompanySupplierResourceSpecializations = () => {
	return new UpdateCompanySupplierResourceSpecializations(makeSupplierRepository());
};

export const makeEvaluateCompanySupplierResource = () => {
	return new EvaluateCompanySupplierResource(makeSupplierRepository());
};
export const makeEvaluateSupplierDocument = () => {
	return new EvaluateSupplierDocument(makeSupplierRepository());
};
export const makeAutoEvaluateCompanySupplierResource = () => {
	return new AutoEvaluateCompanySupplierResource(makeSupplierRepository());
};

export const makeGetCompanySupplierResourceEvaluations = () => {
	return new GetCompanySupplierResourceEvaluations(makeSupplierRepository());
};

export const makeGetResourceBadge = () => {
	return new GetSupplierResourceBadge(makeSupplierRepository());
};

export const makeAddRequirementToSupplierCompanyResource = () => {
	return new AddRequirementToSupplierCompanyResource(makeSupplierRepository());
};

export const makeEvaluateCompanySupplierResourceRequirement = () => {
	return new EvaluateCompanySupplierResourceRequirement(makeSupplierRepository());
};

export const makeUpdateSiteSupplierGlobalStatus = () => {
	return new UpdateSiteSupplierGlobalStatus(makeSupplierRepository());
};

export const makeUpdateCompanySupplierRequirement = () => {
	return new UpdateCompanySupplierRequirement(makeSupplierRepository());
};

export const makeUpdateCompanySupplierRequirements = () => {
	return new UpdateCompanySupplierRequirements(makeSupplierRepository());
};

export const makeAddCompanySupplierRequirementVariant = () => {
	return new AddCompanySupplierRequirementVariant(makeSupplierRepository());
};

export const makeAddCompanySupplierRequirementSpecialization = () => {
	return new AddCompanySupplierRequirementSpecialization(makeSupplierRepository());
};

export const makeRemoveCompanySupplierRequirementVariant = () => {
	return new RemoveCompanySupplierRequirementVariant(makeSupplierRepository());
};

export const makeRemoveCompanySupplierRequirementSpecialization = () => {
	return new RemoveCompanySupplierRequirementSpecialization(makeSupplierRepository());
};

export const makeGetCompanySupplierRequirements = () => {
	return new GetCompanySupplierRequirements(makeSupplierRepository());
};

export const makeDeleteCompanySupplierRequirement = () => {
	return new DeleteCompanySupplierRequirement(makeSupplierRepository());
};

export const makeDeleteSiteSupplierRequirement = () => {
	return new DeleteSiteSupplierRequirement(makeRequirementRepository());
};

export const makeAddCompanySupplierRequirement = () => {
	return new AddCompanySupplierRequirement(makeSupplierRepository());
};

export const makeCreateSupplierResourceDocuments = () => {
	return new CreateSupplierResourceDocument(makeSupplierRepository());
};

export const makeUpdateSupplierResourceDocument = () => {
	return new UpdateSupplierResourceDocument(makeSupplierRepository());
};
