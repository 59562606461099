import { RequirementsViewModel } from "../requirements/RequirementsViewModel";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import {
  makeGetDocumentTypes,
  makeGetGroupRequirementsByResource,
  makeGetRequirementsGroupByVariant,
} from "../../../core/app/factory/requirements/useCases";
import {
  makeAddRequirementToGroup,
  makeRemoveRequirementFromGroup,
} from "../../../core/app/factory/requirementsGroup/useCases";
import Requirement from "../../../domain/entities/requirement";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import DocumentType from "../../../domain/entities/documentType";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { DocumentTypeAdd } from "../../../domain/repositories/siteRepository";

class CompanyReqGroupDetailViewModel extends RequirementsViewModel {
  constructor(private target: RequirementSubject) {
    super();
  }

  async getGroupRequirementsByResource(
    companyId: string,
    requirementGroupId: string,
  ) {
    return await makeGetGroupRequirementsByResource().Call({
      companyId,
      requirementGroupId,
      requirementSubject: this.target,
    });
  }

  async getRequirementsGroupByVariant(
    companyId: string,
    variantId: string,
    requirementGroupId: string,
  ): Promise<Requirement[]> {
    return await makeGetRequirementsGroupByVariant().Call({
      companyId,
      variantId,
      requirementGroupId,
      requirementSubject: this.target,
    });
  }

  async addRequirementToGroup(
    companyId: string,
    requirementGroupId: string,
    documents: DocumentTypeAdd[],
    requirementSubject: RequirementSubject,
    siteIds?: string[],
  ): Promise<Requirement> {
    return await makeAddRequirementToGroup().Call({
      companyId,
      requirementGroupId,
      documents,
      requirementSubject,
      siteIds,
    });
  }

  addRequirementToSite = undefined;

  async removeRequirementFromGroup(
    companyId: string,
    requirementGroupId: string,
    requirementId: string,
    siteIds?: string[],
  ): Promise<void> {
    return await makeRemoveRequirementFromGroup().Call({
      companyId,
      requirementGroupId,
      requirementId,
      siteIds,
    });
  }

  removeRequirementFromSite = undefined;

  async getDocumentTypes(
    companyId: string,
    search?: string,
    tags?: string[],
    sort?: SortMeta,
    pageParam?: number,
    resourceId?: string,
    systemOnly?: boolean,
    category?: DocumentTypeCategory
  ): Promise<DocumentType[]> {
    // this guard is to prevent a useless call with SUPPLIER category
    if (category === DocumentTypeCategory.SUPPLIER) {
      return;
    }
    return await makeGetDocumentTypes().Call({
      companyId,
      filter: category,
      includePublic: true,
      search,
      tags,
      sort,
      pageParam,
    });
  }

  getDocumentTypeForAi(companyId: string, filter: DocumentTypeCategory): Promise<DocumentType[]> {
    throw new Error("Method not implemented.");
  }

  getDocumentTypesForAi(companyId: string, category: DocumentTypeCategory): Promise<DocumentType[]> {
    throw new Error("Method not impleemented");
  }
}

export { CompanyReqGroupDetailViewModel };
