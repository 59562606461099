import { injectable } from 'tsyringe';
import Requirement from '../../domain/entities/requirement';
import RequirementsGroup from '../../domain/entities/requirementsGroup';
import { RequirementSubject } from '../../domain/entities/requirementSubject.enum';
import RequirementsGroupRepository from '../../domain/repositories/requirementsGroupRepository';
import { ApiService } from '../utilities/apiService';
import { DocumentTypeAdd, GetSitesFilter } from '../../domain/repositories/siteRepository';
import Variant from '../../domain/entities/variant';
import { SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import Site from '../../domain/entities/site';

@injectable()
class ServerRequirementsGroupRepository implements RequirementsGroupRepository {
	constructor(private apiService: ApiService) { }

	async getRequirementsGroupById(companyId: string, groupId: string): Promise<RequirementsGroup | undefined> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${groupId}`,
		);
		return response.json();
	}

	async getRequirementsGroups(companyId: string): Promise<RequirementsGroup[]> {
		let response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups`);
		response = await response.json();
		return response['results'] ?? response;
	}

	async createRequirementsGroup(companyId: string, requirementsGroup: RequirementsGroup): Promise<RequirementsGroup> {
		return await this.apiService
			.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(requirementsGroup),
			})
			.then((response) => response.json());
	}

	async updateRequirementsGroup(companyId: string, requirementsGroup: RequirementsGroup): Promise<RequirementsGroup> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${requirementsGroup.id}`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(requirementsGroup),
			},
		);
		return response.json();
	}

	async deleteRequirementsGroup(companyId: string, groupId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${groupId}`, {
			method: 'DELETE',
		});

		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups`);
	}

	async addRequirementToGroup(
		companyId: string,
		groupId: string,
		documents: DocumentTypeAdd[],
		requirementSubject: RequirementSubject,
		siteIds?: string[],
	): Promise<Requirement> {
		const requirements = documents.map((doc) => ({
			documentTypeId: doc.documentTypeId,
			isOptional: doc.isOptional,
			graceDays: doc.graceDays,
			variantsIds: doc.variants.map((variant) => variant.id),
			specializationsIds: doc.specializations.map((specialization) => specialization.id),
			resourceType: requirementSubject,
			source: doc.source
		}));
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${groupId}/requirements`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ requirements, sitesIds: siteIds }),
			},
		);
		return response.json();
	}

	async removeRequirementFromGroup(companyId: string, groupId: string, requirementId: string, siteIds?: string[]): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${groupId}/requirements/${requirementId}`,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ sitesIds: siteIds }),
			},
		);
	}

	async getRequirementsGroupVariants(companyId: string, groupId: string, type: RequirementSubject): Promise<Variant[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${groupId}/variants?type=${type}`,
		);
		if (!response.ok) {
			throw new Error(response.statusText);
		}
		const { results } = await response.json();
		return results;
	}
	async cloneRequirementsGroup(companyId: string, requirementGroupId: string, requirementGroupName: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${requirementGroupId}/clone`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ newName: requirementGroupName }),
			},
		);
	}

	// Public.
	async getPropagableSites(
		companyId: string,
		requirementGroupId: string,
		filter?: GetSitesFilter,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<Site[]> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/owned?${params.toString()}&requirementGroupId=${requirementGroupId}`,
		);
		const { results } = await response.json();
		return results;
	}
}

export default ServerRequirementsGroupRepository;
