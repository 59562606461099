import {
  makeAddRequirementToSiteMachine,
  makeAutoEvaluateSiteMachine,
  makeEvaluateSiteMachine,
  makeGetSiteMachine,
  makeGetSiteMachineDocuments,
  makeGetSiteMachineEvaluations,
  makeUpdateSiteMachineSpecializations,
  makeUpdateSiteMachineVariant,
} from "../../../core/app/factory/siteMachines/usecases";
import { makeGetSiteVariants } from "../../../core/app/factory/variants/usecases";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import { makeGetSiteSpecializations } from "../../../core/app/factory/specializations/usecases";
import {
  SiteMachine,
  SiteMachineParams,
} from "../../../domain/entities/siteMachine";
import Evaluation from "../../../domain/entities/evaluation";
import { ResourceEvaluationState } from "../../../domain/entities/resourceEvaluationState.enum";
import DocumentType from "../../../domain/entities/documentType";
import { makeGetDocumentTypes, makeGetDocumentTypesForAi } from "../../../core/app/factory/documentTypes/usecases";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SiteResourceDetailViewModel } from "../../hooks/Site/useSiteResourceDetailViewModel";
import { SiteResourceDocument } from "../../../domain/entities/document";
import BaseSiteResourceDetailViewModel from "./BaseSiteResourceDetailViewModel";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetResourceEvaluationFilter } from "../../../domain/repositories/siteRepository";
import { makeDeleteSiteSupplierRequirement } from "../../../core/app/factory/suppliers/useCases";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import { GetWorkingSiteDocumentsFilter } from "../../../domain/repositories/filters";

class SiteMachineDetailViewModel
  extends BaseSiteResourceDetailViewModel
  implements SiteResourceDetailViewModel<SiteMachineParams> {
  async getSiteResource(
    companyId: string,
    siteId: string,
    resourceId: string,
  ): Promise<SiteMachine> {
    return await makeGetSiteMachine().Call({
      companyId,
      siteId,
      machineId: resourceId,
    });
  }

  async getSiteResourceDocuments(
    companyId: string,
    siteId: string,
    resourceId: string,
    filter: GetWorkingSiteDocumentsFilter,
    sortDocuments: SortMeta,
    pageParam?: number,
  ): Promise<SiteResourceDocument[]> {
    return await makeGetSiteMachineDocuments().Call({
      companyId,
      siteId,
      machineId: resourceId,
      filter,
      sort: sortDocuments,
      pageParam,
    });
  }

  async evaluateSiteResource(
    companyId: string,
    siteId: string,
    resourceId: string,
    value: ResourceEvaluationState,
  ): Promise<void> {
    await makeEvaluateSiteMachine().Call({
      companyId,
      siteId,
      machineId: resourceId,
      value,
    });
  }

  async autoEvaluateSiteResource(
    companyId: string,
    siteId: string,
    resourceId: string,
  ): Promise<void> {
    await makeAutoEvaluateSiteMachine().Call({
      companyId,
      siteId,
      machineId: resourceId,
    });
  }

  async getSiteResourceEvaluations(
    companyId: string,
    siteId: string,
    resourceId: string,
    pageParam: number,
    sort?: SortMeta,
    filter?: GetResourceEvaluationFilter,
  ): Promise<Evaluation<ResourceEvaluationState>[]> {
    return (await makeGetSiteMachineEvaluations().Call({
      companyId,
      siteId,
      machineId: resourceId,
      pageParam,
      sort,
      filter,
    })) as unknown as Promise<Evaluation<ResourceEvaluationState>[]>;
  }

  async getVariants(companyId: string, siteId: string): Promise<any[]> {
    return await makeGetSiteVariants().Call({
      companyId,
      siteId,
      type: DocumentTypeResourceType.MACHINE,
    });
  }

  async getSpecializations(companyId: string, siteId: string): Promise<any[]> {
    return await makeGetSiteSpecializations().Call({
      companyId,
      siteId,
      type: DocumentTypeResourceType.MACHINE,
    });
  }

  async updateSiteResourceVariant(
    companyId: string,
    machineId: string,
    siteId: string,
    variantId?: string,
  ): Promise<void> {
    await makeUpdateSiteMachineVariant().Call({
      companyId,
      machineId,
      siteId,
      variantId,
    });
  }

  async updateSiteResourceSpecializations(
    companyId: string,
    machineId: string,
    siteId: string,
    specializationIds?: string[],
  ): Promise<void> {
    await makeUpdateSiteMachineSpecializations().Call({
      companyId,
      machineId,
      siteId,
      specializationIds,
    });
  }

  async addRequirementToSiteResource(
    companyId: string,
    machineId: string,
    siteId: string,
    requirements: {
      documentTypeId: string;
      isOptional: boolean;
      graceDays: number;
    }[]
  ): Promise<void> {
    await makeAddRequirementToSiteMachine().Call({
      companyId,
      machineId,
      siteId,
      requirements
    });
  }

  async removeRequirementFromSiteResource(
    companyId: string,
    machineId: string,
    siteId: string,
    requirementId: string,
    subject: RequirementSubject,
  ): Promise<void> {
    await makeDeleteSiteSupplierRequirement().Call({
      companyId,
      siteId,
      requirementId,
      subject,
      resourceId: machineId,
    });
  }

  async getDocumentTypes(
    companyId: string,
    search?: string,
    tags?: string[],
    sort?: SortMeta,
    pageParam?: number,
    resourceId?: string,
    systemOnly?: boolean,
  ): Promise<DocumentType[]> {
    return await makeGetDocumentTypes().Call({
      companyId,
      filter: DocumentTypeCategory.MACHINE,
      //NM:I assigned the opposite of systemOnly because if the latter is true, then includePublic must be false and vice versa.
      includePublic: !systemOnly,
      search,
      tags,
      sort,
      pageParam,
      withFileCount: true,
      resourceId,
      systemOnly,
    });
  }

  async getDocumentTypesForAi(companyId: string, aiTaskId: string, resourceType: DocumentTypeCategory): Promise<DocumentType[]> {
    return await makeGetDocumentTypesForAi().Call({
      companyId,
      aiTaskId,
      resourceType
    });
  }
}

export default SiteMachineDetailViewModel;
