import { FiTag } from "react-icons/fi";
import { FC, MouseEvent, useRef, useState } from "react";
import {
  Flex,
  IconButton,
  Portal,
  useOutsideClick,
  useToast,
} from "@chakra-ui/react";
import Tag from "../../../../domain/entities/tag";
import TagBadge from "../TagBadge";
import MultiTagSelect from "../TagSelect/MultiTagSelect";
import { t } from "i18next";
import TagMoreButton from "../TagMoreButton";

interface VariantListProps {
  tags: Array<Tag>;
  selectedTags: Array<Tag>;
  onAdd?: (variant: Tag) => void;
  onDelete?: (tag: Tag) => void;
  onUpdate?: (tag: Tag) => void;
  width?: number;
  height?: number;
  editable?: boolean;
  isTagLoading?: boolean;
  required?: boolean;
  isCreatable?: boolean;
}

const CellTagList: FC<VariantListProps> = ({
  onAdd,
  onDelete,
  onUpdate,
  isTagLoading,
  isCreatable = true,
  tags,
  selectedTags,
  width,
  required,
  editable,
}) => {
  const toast = useToast();

  const [selectVariantIsVisible, setSelectVariantIsVisible] =
    useState<boolean>(false);
  const selectRef = useRef(null);

  const [buttonX, setButtonX] = useState(0);
  const [buttonY, setButtonY] = useState(0);

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const target = e.target as HTMLElement; // cast EventTarget to HTMLElement
    const rect = target.getBoundingClientRect();
    setButtonX(rect.left - 150);
    setButtonY(rect.bottom);
    setSelectVariantIsVisible(true);
  };

  const handleSelectTag = (variants) => {
    if (variants.length > selectedTags.length) {
      const selectedIds = selectedTags.map((t) => t.id);
      const variant = variants.find((value) => !selectedIds.includes(value.id));
      if (variant) {
        onAdd(variant);
      }
    }

    if (selectedTags?.length > variants?.length) {
      const variantIds = variants.map((v) => v.id);
      const deleteVariant = selectedTags.find(
        (value) => !variantIds.includes(value.id),
      );
      if (deleteVariant) {
        onDelete
          ? onDelete(deleteVariant)
          : toast({
            title: `${t("warning", { ns: "siteResources" })}`,
            description: `${t("variantIsRequired", { ns: "requirements" })}`,
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
      }
    }
  };

  const onMenuCloses = () => setSelectVariantIsVisible(false);

  const createTag = (variantName: string) => {
    const newTag = {
      id: variantName,
      name: variantName,
      color: "",
    };
    onAdd(newTag);
  };

  useOutsideClick({
    ref: selectRef,
    handler: () => setSelectVariantIsVisible(false),
  });

  return (
    <div
      style={{ position: "relative" }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Flex gap={2} width={width} flexWrap={"wrap"}>
        {selectedTags?.slice(0, 2).map((variant) => {
          return (
            <TagBadge
              key={variant.id}
              tag={variant}
              onDelete={onDelete ? () => onDelete(variant) : undefined}
              onUpdate={onUpdate}
              editable={editable && variant.name !== "Default"}
            />
          );
        })}

        {Array.isArray(tags) &&
          tags.filter((v) =>
            Array.isArray(selectedTags)
              ? selectedTags.map((t) => t.id).includes(v.id)
              : true,
          ).length > 2 && (
            <TagMoreButton
              tags={selectedTags.slice(2)}
              onDelete={onDelete}
              onUpdate={onUpdate}
              editable={editable}
            />
          )}

        {onAdd && (
          <IconButton
            size="xs"
            aria-label="manage-variants"
            onClick={handleButtonClick}
            bg="none"
          >
            <FiTag fontSize="16px" color="#767676" />
          </IconButton>
        )}
      </Flex>

      {selectVariantIsVisible && (
        <Portal>
          <div
            ref={selectRef}
            style={{
              position: "absolute",
              left: buttonX,
              top: buttonY,
              width: "300px",
              marginTop: "10px",
              zIndex: 2500,
            }}
          >
            <MultiTagSelect
              tags={tags}
              selectedTags={selectedTags}
              setSelectedTags={handleSelectTag}
              isLoading={isTagLoading}
              onMenuClose={onMenuCloses}
              createTag={isCreatable ? createTag : undefined}
              defaultMenuIsOpen
              required={required}
              isClearable={false}
              backspaceRemovesValue={false}
              autoFocus
            />
          </div>
        </Portal>
      )}
    </div>
  );
};

export default CellTagList;
