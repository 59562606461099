import { makeEvaluateSiteDocument } from "../../../core/app/factory/documents/usecases";
import {
  makeAddRequirementToSiteVehicle,
  makeDeleteSiteVehicle,
} from "../../../core/app/factory/siteVehicles/usecases";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";
import { SiteVehicleParams } from "../../../domain/entities/siteVehicle";
import { SiteResourceDetailViewModel } from "../../hooks/Site/useSiteResourceDetailViewModel";
import SiteVehicleDetailViewModel from "../sites/SiteVehicleDetailViewModel";

class WorkingSiteVehicleDetailViewModel
  extends SiteVehicleDetailViewModel
  implements SiteResourceDetailViewModel<SiteVehicleParams> {
  evaluateSiteResource = undefined;
  async addRequirementToSiteResource(
    companyId: string,
    vehicleId: string,
    siteId: string,
    requirements: {
      documentTypeId: string;
      isOptional: boolean;
      graceDays: number;
    }[]
  ): Promise<void> {
    await makeAddRequirementToSiteVehicle().Call({
      companyId,
      vehicleId,
      siteId,
      requirements
    });
  }
  async evaluateDocument(
    companyId: string,
    siteId: string,
    documentId: string,
    result: ResourceDocumentEvaluationState,
    expiresAt?: Date,
    noEvaluationExpiration?: boolean,
    target?: string,
    resourceId?: string,
  ): Promise<void> {
    await makeEvaluateSiteDocument().Call({
      companyId,
      siteId,
      documentId,
      result,
      expiresAt,
      noEvaluationExpiration,
      target,
      resourceId,
    });
  }

  async deleteSiteResource(
    companyId: string,
    vehicleId: string,
    siteId: string,
  ): Promise<void> {
    await makeDeleteSiteVehicle().Call({ companyId, vehicleId, siteId });
  }
}

export default WorkingSiteVehicleDetailViewModel;
