import { FC, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import RenderIf from "../../components/Permissions/RenderIf";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { Permission } from "../../components/Permissions/Permissions";
import SiteSupplierTable from "../../components/Views/SiteDetails/SiteSupplierTable";
import { useSiteSuppliersViewModel } from "../../hooks/Site/useSiteSuppliersViewModel";
import { InviteSupplierWithVariantPageContainer } from "../../components/Views/WorkingSiteDetails/InviteSupplierWIthVariantPageContainer";
import { useMediaQuery } from "@chakra-ui/react";

interface Props {
  invitePermissions: Permission[];
}

const SiteSuppliersView: FC<Props> = ({ invitePermissions }) => {
  const { siteId } = useParams();
  const { t } = useTranslation();
  const {
    activeSuppliers,
    activeSuppliersNested,
    suppliersHasNextPage,
    suppliersFetchNextPage,
    isFetching,
    deleteSupplier,
    inviteSuppliers,
    documents,
    filterDocuments,
    updateFilterDocuments,
    updateFilterAvailable,
    availableSuppliers,
    availableSuppliersHasNextPage,
    availableSuppliersFetchNextPage,
    availableSuppliersFetching,
    isUserEmailAvailable,
    filterAvailable,
    updateFilterActive,
    filterActive,
    sortActive,
    setSortActive,
    sortAvailable,
    setSortAvailable,
    sortDocuments,
    setSortDocuments,
    tags,
    setSupplierCreationActive,
    supplierVariants,
    invitedSuppliers,
    inviteSuppliersIsLoading,
    deleteSupplierIsLoading,
    filterInvited,
    updateFilterInvited,
    sortInvited,
    setSortInvited,
    invitedSuppliersHasNextPage,
    invitedSuppliersFetchNextPage,
    invitedIsFetching,
    messageInvitation,
    setMessageInvitation,
    renewInvitation,
    setSearch,
    setSearchInvited,
    setSearchToInvite,
  } = useSiteSuppliersViewModel(siteId);

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const [showCreateSupplierModal, setShowCreateSupplierModal] =
    useState<boolean>(false);

  const showCreateSupplier = () => {
    setSupplierCreationActive(true);
    setShowCreateSupplierModal(true);
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={invitePermissions}>
            <ActionBarItem
              icon={FiPlus}
              description={t("inviteSupplier", { ns: "supplier" })}
              onClick={() => showCreateSupplier()}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      <Flex
        flex={1}
        h="100%"
        width={isMobile ? "900px" : "100%"}
        bg="white"
        alignItems="start"
        flexDirection="column"
        padding={10}
      >
        <SiteSupplierTable
          suppliers={activeSuppliers}
          suppliersNested={activeSuppliersNested}
          suppliersHasNextPage={suppliersHasNextPage}
          suppliersFetchNextPage={suppliersFetchNextPage}
          isFetching={isFetching}
          deleteSupplier={deleteSupplier}
          deleteSupplierIsLoading={deleteSupplierIsLoading}
          updateFilterActive={updateFilterActive}
          filterActive={filterActive}
          sortActive={sortActive}
          setSortActive={setSortActive}
          invitedSuppliers={invitedSuppliers}
          filterInvited={filterInvited}
          sortInvited={sortInvited}
          updateFilterInvited={updateFilterInvited}
          setSortInvited={setSortInvited}
          invitedSuppliersHasNextPage={invitedSuppliersHasNextPage}
          invitedSuppliersFetchNextPage={invitedSuppliersFetchNextPage}
          invitedIsFetching={invitedIsFetching}
          autosize={true}
          messageInvitation={messageInvitation}
          renewInvitation={renewInvitation}
          setMessageInvitation={setMessageInvitation}
          setSearch={setSearch}
          setSearchInvited={setSearchInvited}
        />

        {showCreateSupplierModal && (
          <InviteSupplierWithVariantPageContainer
            documents={documents}
            filterDocuments={filterDocuments}
            updateFilterDocuments={updateFilterDocuments}
            sortDocuments={sortDocuments}
            setSortDocuments={setSortDocuments}
            availableSuppliers={availableSuppliers}
            availableSuppliersHasNextPage={availableSuppliersHasNextPage}
            availableSuppliersFetchNextPage={availableSuppliersFetchNextPage}
            availableSuppliersLoading={availableSuppliersFetching}
            filterAvailable={filterAvailable}
            updateFilterAvailable={updateFilterAvailable}
            sortAvailable={sortAvailable}
            setSortAvailable={setSortAvailable}
            inviteSuppliers={inviteSuppliers}
            isLoading={inviteSuppliersIsLoading}
            onClose={() => {
              setSupplierCreationActive(false);
              setShowCreateSupplierModal(false);
            }}
            tags={tags}
            isUserEmailAvailable={isUserEmailAvailable}
            companyVariants={supplierVariants}
            setSearch={setSearchToInvite}
          />
        )}
      </Flex>
    </ContentLayout>
  );
};

export default SiteSuppliersView;
