import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import Typology from "../../../domain/entities/typology";
import Steps from "../../components/Common/stepper/Steps";
import Site, { makeEmptySite } from "../../../domain/entities/site";
import RequirementsGroup from "../../../domain/entities/requirementsGroup";
import InputAnimatedLabel from "../../components/Common/InputAnimatedLabel";
import InputAnimatedSelect from "../../components/Common/InputAnimatedSelect";
import CreatableSelect from "react-select/creatable";

import {
  Box,
  Button,
  Divider,
  Flex,
  Spacer,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import Variant from "../../../domain/entities/variant";
import SingleTagSelect from "../../components/Common/TagSelect/SingleTagSelect";
import Tag from "../../../domain/entities/tag";

interface Props {
  onCancel: () => void;
  onConfirm: (
    site: Site,
    requirementsGroup?: RequirementsGroup,
    typology?: Typology,
    siteVariant?: Variant,
  ) => void;
  requirementsGroups: RequirementsGroup[];
  typologies: Typology[];
  siteVariants: Variant[];
  setCreationRequirementGroup: Dispatch<SetStateAction<string>>;
  createSiteIsLoading: boolean;
}

const CreateSiteView: FC<Props> = ({
  onCancel,
  onConfirm,
  requirementsGroups,
  createSiteIsLoading,
  typologies: allTypologies,
  siteVariants,
  setCreationRequirementGroup,
}) => {
  const { t } = useTranslation("sites");

  const [step, setStep] = useState<number>(0);
  const [site, setSite] = useState<Site>(makeEmptySite());
  const [typology, setTypology] = useState<Tag>(undefined);
  const [siteVariant, setSiteVariant] = useState<Tag>(undefined);
  const [requirementsGroup, setRequirementsGroup] =
    useState<RequirementsGroup>(undefined);

  const nextStep = async () => {
    switch (step) {
      case 0:
        setStep(1);
        break;
      case 1:
        setStep(2);
        break;
      default:
        await onConfirm(
          site,
          requirementsGroup,
          typology as Typology,
          siteVariant as Variant,
        );
        onCancel();
        break;
    }
  };
  const prevStep = () => {
    switch (step) {
      case 0:
        onCancel();
        break;
      case 1:
        setStep(0);
        break;
      default:
        setStep(1);
        break;
    }
  };

  const updateSelectedRequirementsGroup = (id: string) => {
    if (id === "") {
      setRequirementsGroup(undefined);
      return;
    }

    const selected = requirementsGroups.filter((r) => r.id === id);
    setCreationRequirementGroup(
      selected.length > 0 ? selected[0].id : undefined,
    );
    setRequirementsGroup(selected.length > 0 ? selected[0] : undefined);
  };

  const canContinue = (): boolean => {
    if (step === 0) {
      return site.name.trim() !== "" && site.protocol.trim() !== "";
    }
    if (step === 1) {
      return typology !== undefined && requirementsGroup !== undefined;
    }
    if (step === 2) {
      return siteVariant !== undefined;
    }
  };

  return (
    <Modal isOpen onClose={onCancel} size="3xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box padding={2} border="1px" borderColor="gray.200" borderRadius={6}>
            <Steps
              selected={step}
              onSelect={(index) => setStep(index)}
              steps={[
                { title: t("generalInformation") },
                { title: t("documents") },
                { title: t("variant", {ns: 'workers'}) },
              ]}
            />
          </Box>
        </ModalHeader>

        <ModalBody zIndex={"10"} overflow={"unset"}>
          {step === 0 && (
            <Flex align="start" direction="column">
              <Text color={COLORS.sikuroBlue} fontSize="large">
                {t("summary")}
              </Text>
              <Text>{t("create.subtitle")}</Text>
              <Text marginTop={4}>{t("generalInformation")}</Text>
              <Divider />
              <Flex width="100%" direction="row">
                <InputAnimatedLabel
                  defaultValue={site.protocol}
                  isRequired
                  id="protocol"
                  width="50%"
                  marginTop={2}
                  label={t("protocol")}
                  handleOnChange={(_, value) =>
                    setSite({ ...site, protocol: value })
                  }
                />

                <Flex width={4} />

                <InputAnimatedLabel
                  defaultValue={site.name}
                  isRequired
                  id="name"
                  width="50%"
                  marginTop={2}
                  label={t("name")}
                  handleOnChange={(_, value) =>
                    setSite({ ...site, name: value })
                  }
                />
              </Flex>

              <Text marginTop={4}>{t("location")}</Text>
              <Divider />
              <Flex width="100%" direction="row">
                <InputAnimatedLabel
                  defaultValue={site.address}
                  id="address"
                  width="60%"
                  marginTop={2}
                  label={t("address")}
                  handleOnChange={(_, value) =>
                    setSite({ ...site, address: value })
                  }
                />

                <Flex width={4} />

                <InputAnimatedLabel
                  defaultValue={site.city}
                  id="city"
                  width="30%"
                  marginTop={2}
                  label={t("city")}
                  handleOnChange={(_, value) =>
                    setSite({ ...site, city: value })
                  }
                />

                <Flex width={4} />

                <InputAnimatedLabel
                  defaultValue={site.zipCode}
                  id="zipCode"
                  width="20%"
                  marginTop={2}
                  label={t("zipCode")}
                  handleOnChange={(_, value) =>
                    setSite({ ...site, zipCode: value })
                  }
                />
              </Flex>
            </Flex>
          )}

          {step === 1 && (
            <Flex align="start" direction="column">
              <Text color={COLORS.sikuroBlue} fontSize="large">
                {t("sitePresets")}
              </Text>
              <Text mt={1} mb={3}>
                {t("sitePresetsDescription")}
              </Text>

              <SingleTagSelect
                setSelectedTag={setTypology}
                selectedTag={typology}
                defaultMenuIsOpen={false}
                tags={allTypologies}
                styles={{ control: (base) => ({ ...base, width: "100%" }) }}
                noSelectionLabel={t("noPresetSelected", { ns: "sites" })}
                fullWidth
              />

              <Text mt={3} color={COLORS.sikuroBlue} fontSize="large">
                {t("requirementsGroup")}
              </Text>
              <Text my={1}>{t("requirementsGroupDescription")}</Text>
              <CreatableSelect
                id="requirements-groups"
                menuPosition={"fixed"}
                placeholder={
                  t("noRequirementsGroupSelected") ??
                  t("select", { ns: "common" })
                }
                options={requirementsGroups}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                styles={{
                  container: () => ({
                    width: "100%",
                  }),
                }}
                onChange={(options) => {
                  if (options) {
                    updateSelectedRequirementsGroup(options.id);
                  }
                }}
              />
            </Flex>
          )}

          {step === 2 && (
            <Flex mt={3} align="start" direction="column" zIndex={"10"}>
              <Text color={COLORS.sikuroBlue} fontSize="large">
                {t("companyAndSiteVariants")}
              </Text>

              <Text my={3}>{t("selectSiteVariant")}</Text>
              <SingleTagSelect
                setSelectedTag={setSiteVariant}
                selectedTag={siteVariant}
                defaultMenuIsOpen={false}
                tags={siteVariants}
                fullWidth
              />
            </Flex>
          )}
        </ModalBody>

        <ModalFooter zIndex={"1"}>
          <Spacer />
          <Button
            onClick={nextStep}
            isDisabled={!canContinue()}
            bgColor={COLORS.sikuroBlue}
            size="sm"
            color="white"
            isLoading={createSiteIsLoading}
          >
            {t(step <= 0 ? "next" : "confirm", { ns: "common" })}
          </Button>
          <Button
            onClick={prevStep}
            bgColor={COLORS.red}
            size="sm"
            color="white"
            marginLeft={4}
          >
            {t(step <= 0 ? "cancel" : "back", { ns: "common" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateSiteView;
