import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  ModalCloseButton,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  DocumentActionLogEntry,
  DocumentFileLogEntry,
} from "../../../../domain/entities/documentLogEntry";
import Evaluation from "../../../../domain/entities/evaluation";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import React, { useEffect } from "react";
import {
  Filter,
  Sort,
  UpdateFilter,
  UpdateSort,
} from "../../../hooks/Site/useSiteResourceDetailViewModel";
import DocumentEvaluationsTable from "../Documents/DocumentEvaluationsTable";
import DocumentHistoryTable from "../Documents/DocumentHistoryTable";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import DocumentActionsHistoryTable from "../Documents/DocumentActionsHistoryTable";

type DocumentInfoModalProps = {
  onClose: () => void;
  tab: 0 | 1;
  changeTab: (tab: 0 | 1) => void;
  getDocumentLogs: () => void;
  getDocumentEvaluations: () => void;
  documentFileLogs: DocumentFileLogEntry[];
  documentActionLogEntry?: DocumentActionLogEntry[];
  documentEvaluations: Evaluation<ResourceDocumentEvaluationState>[];
  updateFilterDocumentEvaluations: UpdateFilter;
  updateFilterDocumentLog: UpdateFilter;
  filterDocumentLog: Filter;
  filterDocumentEvaluations: Filter;
  sortDocumentEvaluations: Sort;
  sortDocumentLog: SortMeta;
  updateSortDocumentEvaluations: UpdateSort;
  setSortDocumentLog: (sort: SortMeta) => void;
  documentLogsFetching: boolean;
  fileLogsHasNextPage?: boolean;
  fileLogsFetchNextPage?: () => void;
  docLogsHasNextPage?: boolean;
  docLogsFetchNextPage?: () => void;
  documentEvaluationsFetching: boolean;
  showDocumentEvaluations?: boolean;
};

const DocumentInfoModal = ({
  tab,
  changeTab,
  onClose,
  getDocumentLogs,
  getDocumentEvaluations,
  documentFileLogs,
  documentEvaluations,
  documentActionLogEntry,
  updateFilterDocumentEvaluations,
  updateFilterDocumentLog,
  filterDocumentLog,
  filterDocumentEvaluations,
  sortDocumentEvaluations,
  sortDocumentLog,
  updateSortDocumentEvaluations,
  setSortDocumentLog,
  fileLogsHasNextPage,
  fileLogsFetchNextPage,
  docLogsHasNextPage,
  docLogsFetchNextPage,
  documentLogsFetching,
  documentEvaluationsFetching,
  showDocumentEvaluations = true,
}: DocumentInfoModalProps) => {
  const { t } = useTranslation("documents");

  useEffect(() => {
    if (tab === 0) {
      getDocumentEvaluations();
    }
    else {
      getDocumentLogs();
    }
  }, [tab]);

  useEffect(() => {
    getDocumentEvaluations();
  }, []);

  return (
    <Modal isOpen={true} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent style={{ background: "white", padding: "2rem" }}>
        <ModalHeader display="flex" justifyContent="space-between">
          {t("documentHistory")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {showDocumentEvaluations && (
            <Tabs index={tab} onChange={changeTab} isFitted defaultIndex={0}>
              <TabList>
                <Tab>{t("documentEvaluations")}</Tab>
                <Tab>{t("fileHistory")}</Tab>
              </TabList>

              <TabPanels>
                <TabPanel px={0}>
                  <DocumentEvaluationsTable
                    autosize
                    documentEvaluations={documentEvaluations}
                    updateFilterDocumentEvaluations={
                      updateFilterDocumentEvaluations
                    }
                    filterDocumentEvaluations={filterDocumentEvaluations}
                    sortDocumentEvaluations={sortDocumentEvaluations}
                    updateSortDocumentEvaluations={
                      updateSortDocumentEvaluations
                    }
                    documentEvaluationsFetching={documentEvaluationsFetching}
                  />
                </TabPanel>
                <TabPanel px={0}>
                  <DocumentHistoryTable
                    autosize
                    documentFileLogs={documentFileLogs}
                    updateFilterDocumentLog={updateFilterDocumentLog}
                    filterDocumentLog={filterDocumentLog}
                    sortDocumentLog={sortDocumentLog}
                    setSortDocumentLog={setSortDocumentLog}
                    documentLogsFetching={documentLogsFetching}
                    fetchNextPage={fileLogsFetchNextPage}
                    hasNextPage={fileLogsHasNextPage}
                  />
                </TabPanel>
                <TabPanel px={0}>
                  <DocumentActionsHistoryTable
                    autosize
                    documentFileLogs={documentActionLogEntry}
                    updateFilterDocumentLog={updateFilterDocumentLog} // Same table, same filters, may change in future releases
                    filterDocumentLog={filterDocumentLog}
                    sortDocumentLog={sortDocumentLog}
                    setSortDocumentLog={setSortDocumentLog}
                    documentLogsFetching={documentLogsFetching}
                    hasNextPage={docLogsHasNextPage}
                    fetchNextPage={docLogsFetchNextPage}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}

          {!showDocumentEvaluations && (
            <Flex flexDirection="column">
              <DocumentHistoryTable
                autosize
                documentFileLogs={documentFileLogs}
                updateFilterDocumentLog={updateFilterDocumentLog}
                filterDocumentLog={filterDocumentLog}
                sortDocumentLog={sortDocumentLog}
                setSortDocumentLog={setSortDocumentLog}
                documentLogsFetching={documentLogsFetching}
                fetchNextPage={fileLogsFetchNextPage}
                hasNextPage={fileLogsHasNextPage}
              />
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DocumentInfoModal;
