import { injectable } from 'tsyringe';
import Company from '../../domain/entities/company';
import Supplier from '../../domain/entities/supplier';
import Evaluation from '../../domain/entities/evaluation';
import Requirement from '../../domain/entities/requirement';
import SupplierRepository, {
	ActiveSubcontractorsFilter,
	ActiveSuppliersFilter,
	AvailableSuppliersFilter,
	GetGlobalSupplierFilters,
	GetSupplierChemicalsFilters,
	GetSupplierMachinesFilters,
	GetSupplierToolsFilters,
	GetSupplierVehiclesFilters,
	GetSupplierWorkersFilters,
	GetWorkingSiteFilter,
	InvitedSuppliersFilter,
	UnlistedCompany,
} from '../../domain/repositories/supplierRepository';
import { SupplierEvaluationState } from '../../domain/entities/supplierEvaluationState.enum';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import { SupplierStatus } from '../../domain/entities/supplierStatus.enum';
import { ApiService } from '../utilities/apiService';
import { dateIntervals } from '../utilities/filters';
import { GetEvaluationsResponse, mapEvaluation } from '../responses/evaluations';
import { ResourceEvaluationState } from '../../domain/entities/resourceEvaluationState.enum';
import { parseCompany } from '../responses/company';
import { ResourceDocumentEvaluationState } from '../../domain/entities/resourceDocumentEvaluationState.enum';
import Document from '../../domain/entities/document';
import { dateToRFC3339, removeEmptyAttributes } from '../../utils';
import { GetDocumentEvaluationFilter, GetDocumentsFilter } from '../../domain/repositories/documentRepository';
import { mapSiteWorker, SupplierWorker } from '../../domain/entities/siteWorker';
import Variant from '../../domain/entities/variant';
import { RequirementSubject } from '../../domain/entities/requirementSubject.enum';
import { SiteChemicalParams } from '../../domain/entities/siteChemical';
import { SiteMachineParams } from '../../domain/entities/siteMachine';
import { SiteToolParams } from '../../domain/entities/siteTool';
import { SiteVehicleParams } from '../../domain/entities/siteVehicle';
import { SupplierResource } from '../../domain/entities/supplierResource';
import Tag from '../../domain/entities/tag';
import { DocumentTypeResourceType } from '../../mock/models/document-type';
import FileEntity from '../../domain/entities/file';
import { InvitedSupplier } from '../../domain/entities/invitedSupplier';
import { GetResourceEvaluationFilter } from '../../domain/repositories/siteRepository';
import { Filter } from '../../presentation/hooks/Site/useSiteResourceDetailViewModel';
import { mapApiResponseToDocument } from '../adapters/getResourceDocuments';
import { GetAvailableBadgesFilters, GetBadgesFilters } from '../../domain/repositories/badgeRepository';
import Badge from '../../domain/entities/badge';
import { BadgeStatusSite } from '../../presentation/hooks/Badge/useBadgeDetailViewModel';
import { ResourceType } from '../../presentation/screens/Site/ResourceSelectableTable';
import { DocumentTypeCategory } from '../../domain/entities/documentTypeCategory.enum';

@injectable()
class ServerSupplierRepository implements SupplierRepository {
	constructor(private apiService: ApiService) { }

	async getSupplierById(companyId: string, siteId: string, supplierId: string): Promise<Supplier | null> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${supplierId}`
		);
		if (!response.ok) {
			return null;
		}
		const {
			company: { street, cap, city, ...company },
			...supplier
		} = await response.json();
		return {
			...supplier,
			company: {
				...company,
				address: {
					street,
					cap,
					city,
				},
			},
		};
	}

	// return the suppliers entities where the company is given by the companyId parameter
	// e.g. if companyId is my company ID I will get all the Supplier entities that represents where my company is a supplier
	async getSuppliersByCompany(companyId: string, filter?: GetWorkingSiteFilter, sort?: SortMeta): Promise<Supplier[]> {
		const sanitizedFilter = Object.keys(filter).reduce((acc, key) => {
			if (filter[key] !== null && filter[key] !== undefined && filter[key] !== 'null') {
				acc[key] = filter[key];
			}
			return acc;
		}, {} as Record<string, any>);
		const params = new URLSearchParams({
			...sanitizedFilter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/working?${params.toString()}`
		);
		const suppliers = await response.json();
		return suppliers.results.map((s) => ({ ...s, status: s.supplier.status })) ?? [];
	}

	async getActiveSuppliers(
		siteId: string,
		companyId: string,
		filter?: ActiveSuppliersFilter,
		sort?: SortMeta,
		pageParam?: number,
		nested?: boolean
	): Promise<Supplier[]> {
		const { subscribeDate, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...dateIntervals(subscribeDate),
			...sort,
		});

		let suppliersNestedUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers?${params.toString()}`;
		if (nested) {
			suppliersNestedUrl = suppliersNestedUrl + '&nested=true';
		}

		const response = await this.apiService.fetchWithToken(suppliersNestedUrl);

		if (!response.ok) {
			throw new Error('Failed to get active supplier');
		}

		const { results } = await response.json();

		return results;
	}

	async getInvitedSuppliers(
		siteId: string,
		companyId: string,
		filter?: InvitedSuppliersFilter,
		sort?: SortMeta,
		pageParam?: number
	): Promise<InvitedSupplier[]> {
		const { sendDate, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...dateIntervals(sendDate),
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/invited?${params.toString()}`
		);

		if (!response.ok) {
			throw new Error('Failed to get invited suppliers');
		}

		const { results } = await response.json();

		return results;
	}

	async getResourceBadge(
		companyId: string,
		resource: string,
		resourceId: string,
		sort?: SortMeta,
		filter?: GetBadgesFilters,
		pageParam?: number,
		supplierId?: string
	): Promise<PaginatedResults<Badge>> {
		const { tagIds, ...restFilter } = filter || {};

		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: '25',
			...{
				...restFilter,
			},
			...sort,
		});

		tagIds?.length > 0 && tagIds.forEach((tag) => params.append('tagIds[]', tag));
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT
			}/companies/${companyId}/suppliers/${supplierId}/${resource}s/${resourceId}/badges?${params.toString()}`
		);
		const badges = await response.json();

		return badges;
	}

	async getBadgeSites(companyId: string, badgeId: string): Promise<BadgeStatusSite[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/${badgeId}/sites`
		);
		const badgeSites = await response.json();
		return badgeSites['results'] ?? [];
	}

	async getAvailableSuppliers(
		siteId: string,
		companyId: string,
		filter?: AvailableSuppliersFilter,
		sort?: SortMeta,
		pageParam?: number
	): Promise<Company[]> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/companies?${params.toString()}`
		);
		if (!response.ok) {
			throw new Error('Failed to get available supplier');
		}
		const { results } = await response.json();
		return results;
	}

	async getAvailableBadges(
		companyId: string,
		resource: string,
		resourceId: string,
		sort?: SortMeta,
		filter?: GetAvailableBadgesFilters,
		supplierId?: string
	): Promise<Badge[]> {
		const { tagIds, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			...{ ...restFilter },
			...sort,
		});
		tagIds?.length > 0 && tagIds.forEach((tag) => params.append('tagIds[]', tag));
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT
			}/companies/${companyId}/suppliers/${supplierId}/${resource}s/${resourceId}/badges/linkable?${params.toString()}`
		);
		const data = await response.json();
		return data.results;
	}

	async inviteSuppliers(
		companyId: string,
		siteId: string,
		supplierIds: string[],
		documentIds?: string[],
		supplierVariant?: string,
		unlistedCompany?: {
			name: string;
			email: string;
		}
	): Promise<void> {

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					documentIds,
					...(supplierIds && supplierIds.length > 0 ? { supplierIds } : { unlistedCompany }),
					variantId: supplierVariant
				}),
			}
		);
		if (!response.ok) {
			throw new Error('Failed to create supplier');
		}
	}

	async inviteSubcontractors(
		companyId: string,
		siteId: string,
		subcontractorIds: string[],
		companyVariant: string,
		documentIds?: string[],
		unlistedCompany?: UnlistedCompany
	): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/subcontractors`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					documentIds,
					...(subcontractorIds && subcontractorIds.length > 0 ? { subcontractorIds } : { unlistedCompany }),
					variantId: companyVariant
				}),
			}
		);
		if (!response.ok) {
			throw new Error('Failed to create supplier');
		}
	}

	async deleteSupplier(companyId: string, siteId: string, supplierId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${supplierId}`,
			{ method: 'DELETE' }
		);
	}

	async getSupplierEvaluations(
		companyId: string,
		siteId: string,
		supplierId: string,
		pageParam: number,
		sort?: SortMeta,
		filter?: GetResourceEvaluationFilter
	): Promise<Evaluation<SupplierEvaluationState>[]> {
		const query = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...sort,
			...filter,
		}).toString();

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${supplierId}/evaluations?${query}`
		);
		const { results }: GetEvaluationsResponse<ResourceEvaluationState> = await response.json();

		return results.map(mapEvaluation);
	}

	async evaluateSupplier(
		companyId: string,
		siteId: string,
		supplierId: string,
		value: SupplierEvaluationState
	): Promise<Evaluation<SupplierEvaluationState>> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${supplierId}/evaluations`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ value }),
			}
		);
		return response.json();
	}

	async autoEvaluateSupplier(companyId: string, siteId: string, supplierId: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${supplierId}/evaluate`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		if (!response.ok) {
			throw new Error('Failed to auto evaluate supplier');
		}
	}

	async updateSupplierVariant(companyId: string, siteId: string, supplierId: string, type: 'site' | 'company', variantId?: string): Promise<void> {
		const params = new URLSearchParams({ type, variantId });
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}` +
			`/companies/${companyId}` +
			`/sites/${siteId}` +
			`/suppliers/${supplierId}/variants?${params.toString()}`,
			{
				method: 'POST',
			}
		);
	}

	async addRequirementToSupplier(
		companyId: string,
		siteId: string,
		supplierId: string,
		requirements: {
			documentTypeId: string;
			isOptional: boolean;
			graceDays: number;
		}[],
		source?: DocumentTypeCategory
	): Promise<Requirement> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${supplierId}/requirements`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ requirements, source }),
			}
		);

		return response.json();
	}

	async setSupplierStatus(companyId: string, siteId: string, supplierId: string, status: SupplierStatus): Promise<Supplier> {
		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${supplierId}/status`;
		const response = await this.apiService.fetchWithToken(url, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ status }),
		});

		return response.json();
	}

	async getActiveSubcontractors(
		companyId: string,
		siteId: string,
		filter?: ActiveSubcontractorsFilter,
		sort?: SortMeta,
		pageParam?: number
	): Promise<Supplier[]> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/subcontractors?${params.toString()}`,
			{ method: 'GET' }
		);
		if (!response.ok) {
			throw new Error('Failed to get active subcontractors');
		}

		const { results } = await response.json();

		return results;
	}

	async deleteSubcontractor(clientId: string, siteId: string, subcontractorId: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${clientId}/sites/${siteId}/subcontractors/${subcontractorId}`,
			{ method: 'DELETE' }
		);
		if (!response.ok) {
			throw new Error('Failed to delete subcontractor');
		}
	}

	async getCompanySuppliers(companyId: string, filter?: GetGlobalSupplierFilters, sort?: SortMeta, pageParam?: number): Promise<Company[]> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers?${params.toString()}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company suppliers');
		}
		const { results } = await response.json();
		return results.map((payload) => {
			return {
				id: payload.company.id,
				logo: payload.company.logo,
				name: payload.company.name,
				fiscalCode: payload.company.fiscalCode,
				phoneNumber: payload.company.phoneNumber,
				pec: payload.company.pec,
				vat: payload.company.vat,
				email: payload.company.email,
				address: {
					street: payload.company.street,
					city: payload.company.city,
					cap: payload.company.cap,
				},
				medic: payload.company.medic,
				rspp: payload.company.rspp,
				referent: payload.company.referent,
				employer: payload.company.employer,
				rls: payload.company.rls,
				businessSize: payload.company.businessSize,
				ccnl: payload.company.ccnl,
				vatCountryCode: payload.company.vatCountryCode,
				status: payload.status,
			};
		});
	}

	async getCompanySupplier(companyId: string, supplierId: string): Promise<Supplier> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier');
		}
		const supplier = await response.json();
		return {
			...supplier,
			company: parseCompany(supplier),
		};
	}

	async checkSupplierRequirements(companyId: string, supplierId: string): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/check-requirements`
		);
		if (!response.ok) {
			throw new Error('Failed to verify supplier requirements');
		}
		const { hasRequirements } = await response.json();
		return hasRequirements;
	}

	async checkSupplierResourceRequirements(companyId: string, supplierId: string, resourceId: string, type: RequirementSubject): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/${type}s/${resourceId}/check-requirements`
		);
		if (!response.ok) {
			throw new Error('Failed to verify supplier requirements');
		}
		const { hasRequirements } = await response.json();
		return hasRequirements;
	}

	async setSupplierRequirements(companyId: string, supplierId: string, requirementGroupId: string, variantId?: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/set-requirements`,
			{
				method: 'POST',
				body: JSON.stringify({ requirementGroupId, variantId }),
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		if (!response.ok) {
			throw new Error('Failed to verify supplier requirements');
		}
	}

	async setSupplierResourceRequirements(
		companyId: string,
		supplierId: string,
		resourceId: string,
		type: RequirementSubject,
		variantId?: string,
		specializationIds?: string[]
	): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/${type}s/${resourceId}/set-requirements`,
			{
				method: 'POST',
				body: JSON.stringify({ specializationIds, variantId }),
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		if (!response.ok) {
			throw new Error('Failed to verify supplier requirements');
		}
	}

	async getSupplierDocuments(
		companyId: string,
		supplierId: string,
		filter?: GetDocumentsFilter,
		sort?: SortMeta,
		pageParam?: number
	): Promise<Document[]> {
		const { tags, expiresAt, ...restFilter } = filter || {};

		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...dateIntervals(expiresAt),
			...sort,
		});
		tags?.length > 0 && tags.forEach((tag) => params.append('tagIds[]', tag));
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/documents?${params.toString()}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier requirements');
		}
		const documents = await response.json();
		return documents.results ? Object.values(documents.results).map(mapApiResponseToDocument) : [];
	}

	async createSupplierDocument(
		companyId: string,
		supplierId: string,
		isPublic: boolean,
		documentTypeId: string,
		siteIds: string[],
		result?: ResourceDocumentEvaluationState,
		expiresAt?: Date,
		files?: FileEntity
	): Promise<void> {
		const formData = new FormData();
		const fileBinaries = files ? Array.from(files?.binaries) : undefined;
		if (fileBinaries) {
			fileBinaries.forEach((f) => {
				formData.append('files[]', f);
			});
		}
		if (siteIds) {
			siteIds?.forEach((s) => {
				formData.append('sitesIds[]', s);
			});
		}
		formData.append('isPublic', isPublic ? 'true' : 'false');
		formData.append('documentTypeId', documentTypeId);
		if (result) {
			formData.append('result', result);
		}

		formData.append('expiresAt', expiresAt ? dateToRFC3339(expiresAt, true) : '');
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/documents`,
			{
				method: 'POST',
				body: formData,
			}
		);
		if (!response.ok) {
			throw new Error('Failed to create the document for the supplier');
		}
	}

	async updateSupplierDocument(companyId: string, supplierId: string, document: Document, siteIds?: string[]): Promise<void> {
		const updateDocumentUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${document.id}`;
		const body = JSON.stringify({
			expiresAt: document?.expiresAt ? dateToRFC3339(new Date(document?.expiresAt), true) : '',
			isPublic: document?.isPublic,
			sitesIds: siteIds,
		});
		await this.apiService.fetchWithToken(updateDocumentUrl, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body,
		});
	}

	async createSupplierResourceDocument(
		companyId: string,
		supplierId: string,
		isPublic: boolean,
		documentTypeId: string,
		siteIds: string[],
		target: string,
		resourceId: string,
		result?: ResourceDocumentEvaluationState,
		expiresAt?: Date,
		files?: FileEntity
	): Promise<void> {
		const formData = new FormData();
		const fileBinaries = files ? Array.from(files?.binaries) : undefined;
		if (fileBinaries) {
			fileBinaries.forEach((f) => {
				formData.append('files[]', f);
			});
		}
		if (siteIds) {
			siteIds?.forEach((s) => {
				formData.append('sitesIds[]', s);
			});
		}
		formData.append('isPublic', isPublic ? 'true' : 'false');
		formData.append('documentTypeId', documentTypeId);
		if (result) {
			formData.append('result', result);
		}

		formData.append('expiresAt', expiresAt ? dateToRFC3339(expiresAt, true) : '');

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/${target}s/${resourceId}/documents`,
			{
				method: 'POST',
				body: formData,
			}
		);
		if (!response.ok) {
			throw new Error("Failed to create the document for the supplier's resource");
		}
	}

	async updateSupplierResourceDocument(
		companyId: string,
		supplierId: string,
		document: Document,
		target: string,
		resourceId: string,
		siteIds?: string[]
	): Promise<void> {
		const updateDocumentUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${document.id}`;

		await this.apiService.fetchWithToken(updateDocumentUrl, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				expiresAt: document?.expiresAt ? dateToRFC3339(new Date(document?.expiresAt), true) : '',
				isPublic: document?.isPublic,
				sitesIds: siteIds,
			}),
		});
	}

	async updateCompanySupplierVariant(companyId: string, supplierId: string, variantId?: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/variants`,
			{
				method: 'PUT',
				body: JSON.stringify({ variantId }),
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		if (!response.ok) {
			throw new Error('Failed to update company supplier variant');
		}
	}

	async evaluateCompanySupplierRequirement(
		companyId: string,
		supplierId: string,
		requirementId: string,
		result: ResourceDocumentEvaluationState,
		expiresAt?: Date
	): Promise<void> {
		const body = JSON.stringify({
			result,
			...(expiresAt && { expiresAt: dateToRFC3339(expiresAt, true) }),
		});
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/requirements/${requirementId}/evaluations`,
			{
				method: 'POST',
				body,
				headers: {
					'Content-Type': 'application/json',
				},
			},
		);
	}

	async evaluateSupplierDocument(
		companyId: string,
		supplierId: string,
		documentId: string,
		result: ResourceDocumentEvaluationState,
		expiresAt?: Date,
		noEvaluationExpiration?: boolean,
		siteIds?: string[],
		selectAll?: boolean,
	): Promise<void> {
		if (!result) {
			return;
		}
		const body = JSON.stringify(
			expiresAt
				? {
					result,
					sitesIds: siteIds,
					selectAll,
					expiresAt: dateToRFC3339(expiresAt, true),
					noEvaluationExpiration,
				}
				: {
					result,
					sitesIds: siteIds,
					selectAll,
					expiresAt: '',
					noEvaluationExpiration,
				},
		);
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/documents/${documentId}/evaluations`,
			{
				method: 'POST',
				body,
				headers: {
					'Content-Type': 'application/json',
				},
			},
		);
	}

	async getSupplierDocumentEvaluations(
		companyId: string,
		supplierId: string,
		documentId: string,
		resourceType: string,
		resourceId: string,
		filter?: GetDocumentEvaluationFilter,
		sort?: SortMeta
	): Promise<Evaluation<ResourceDocumentEvaluationState, Document>[]> {
		const { createdAt, ...restFilter } = filter ?? {};
		const params = new URLSearchParams({
			...removeEmptyAttributes(restFilter),
			...dateIntervals(createdAt),
			...sort,
		});
		const url = resourceType
			? `${process.env.REACT_APP_SERVER_API_ENDPOINT
			}/companies/${companyId}/suppliers/${supplierId}/${resourceType}s/${resourceId}/documents/${documentId}/evaluations?${params.toString()}`
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT
			}/companies/${companyId}/suppliers/${supplierId}/documents/${documentId}/evaluations?${params.toString()}`;
		const response = await this.apiService.fetchWithToken(url);
		const { results }: GetEvaluationsResponse<ResourceDocumentEvaluationState> = await response.json();
		return results.map(mapEvaluation);
	}

	async getVariantsForCompanySupplierRequirements(companyId: string, supplierId: string, subject: RequirementSubject): Promise<Variant[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/requirements/variants?subject=${subject}`
		);
		if (!response.ok) {
			throw new Error('Failed to get variants for company supplier requirements');
		}
		const { results } = await response.json();
		return results;
	}

	async getSpecializationsForCompanySupplierRequirements(companyId: string, supplierId: string, subject: RequirementSubject): Promise<Variant[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/requirements/specializations?subject=${subject}`
		);
		if (!response.ok) {
			throw new Error('Failed to get specializations for company supplier requirements');
		}
		const { results } = await response.json();
		return results;
	}

	async getCompanySupplierWorkers(
		companyId: string,
		supplierId: string,
		page: number,
		perPage: number,
		filter?: GetSupplierWorkersFilters,
		sort?: SortMeta
	): Promise<PaginatedResults<SupplierWorker>> {
		const { variantIds, specializationIds, ...restFilter } = filter ?? {};
		const params = new URLSearchParams({
			page: String(page),
			perPage: String(perPage),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/workers?${params.toString()}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier workers');
		}
		const data = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteWorker(supplierId)),
		};
	}

	async getCompanySupplierMachines(
		companyId: string,
		supplierId: string,
		page: number,
		perPage: number,
		filter?: GetSupplierMachinesFilters,
		sort?: SortMeta
	): Promise<PaginatedResults<SupplierResource<SiteMachineParams>>> {
		const { variantIds, specializationIds, ...restFilter } = filter ?? {};
		const params = new URLSearchParams({
			page: String(page),
			perPage: String(perPage),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/machines?${params.toString()}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier workers');
		}
		return await response.json();
	}

	async getCompanySupplierVehicles(
		companyId: string,
		supplierId: string,
		page: number,
		perPage: number,
		filter?: GetSupplierVehiclesFilters,
		sort?: SortMeta
	): Promise<PaginatedResults<SupplierResource<SiteVehicleParams>>> {
		const { variantIds, specializationIds, ...restFilter } = filter ?? {};
		const params = new URLSearchParams({
			page: String(page),
			perPage: String(perPage),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/vehicles?${params.toString()}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier workers');
		}
		return await response.json();
	}

	async getCompanySupplierTools(
		companyId: string,
		supplierId: string,
		page: number,
		perPage: number,
		filter?: GetSupplierToolsFilters,
		sort?: SortMeta
	): Promise<PaginatedResults<SupplierResource<SiteToolParams>>> {
		const { variantIds, specializationIds, ...restFilter } = filter ?? {};
		const params = new URLSearchParams({
			page: String(page),
			perPage: String(perPage),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/tools?${params.toString()}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier workers');
		}
		return await response.json();
	}

	async getCompanySupplierChemicals(
		companyId: string,
		supplierId: string,
		page: number,
		perPage: number,
		filter?: GetSupplierChemicalsFilters,
		sort?: SortMeta
	): Promise<PaginatedResults<SupplierResource<SiteChemicalParams>>> {
		const { variantIds, specializationIds, ...restFilter } = filter ?? {};
		const params = new URLSearchParams({
			page: String(page),
			perPage: String(perPage),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/chemicals?${params.toString()}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier workers');
		}
		return await response.json();
	}

	async getCompanySupplierWorker(companyId: string, supplierId: string, workerId: string): Promise<SupplierWorker> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/workers/${workerId}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier worker');
		}
		const worker = await response.json();
		return mapSiteWorker(supplierId)(worker);
	}

	async getCompanySupplierChemical(companyId: string, supplierId: string, chemicalId: string): Promise<SupplierResource<SiteChemicalParams>> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/chemicals/${chemicalId}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier worker');
		}
		const chemical = await response.json();
		return chemical;
	}

	async getCompanySupplierMachine(companyId: string, supplierId: string, machineId: string): Promise<SupplierResource<SiteMachineParams>> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/machines/${machineId}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier worker');
		}
		const machine = await response.json();
		return machine;
	}

	async getCompanySupplierTool(companyId: string, supplierId: string, toolId: string): Promise<SupplierResource<SiteToolParams>> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/tools/${toolId}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier worker');
		}
		const tool = await response.json();
		return tool;
	}

	async getCompanySupplierVehicle(companyId: string, supplierId: string, vehicleId: string): Promise<SupplierResource<SiteVehicleParams>> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/vehicles/${vehicleId}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier worker');
		}
		const vehicle = await response.json();
		return vehicle;
	}

	async getSupplierResourceDocuments(
		companyId: string,
		supplierId: string,
		resourceId: string,
		type: RequirementSubject,
		pageParam: number,
		sortDocuments: SortMeta,
		filterDocuments: Filter
	): Promise<Document[]> {
		const query = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...sortDocuments,
			...filterDocuments,
		}).toString();

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/${type}s/${resourceId}/documents?${query}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier worker requirements');
		}
		const { results } = await response.json();
		const mappedDocuments: Document[] = results ? results.map(mapApiResponseToDocument) : [];

		return mappedDocuments;
	}

	async updateCompanySupplierResourceVariant(
		companyId: string,
		supplierId: string,
		resourceId: string,
		type: RequirementSubject,
		variantId?: string
	): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/${type}s/${resourceId}/variants`,
			{
				method: 'PUT',
				body: JSON.stringify({ variantId }),
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		if (!response.ok) {
			throw new Error('Failed to update company supplier variant');
		}
	}

	async updateCompanySupplierResourceSpecializations(
		companyId: string,
		supplierId: string,
		resourceId: string,
		type: RequirementSubject,
		specializationIds?: string[]
	): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/${type}s/${resourceId}/specializations`,
			{
				method: 'PUT',
				body: JSON.stringify({ specializationIds }),
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		if (!response.ok) {
			throw new Error('Failed to update company supplier variant');
		}
	}

	async addRequirementToSupplierCompanyResource(
		companyId: string,
		resourceId: string,
		supplierId: string,
		documentTypeId: string,
		isOptional: boolean,
		graceDays: number,
		type: RequirementSubject
	): Promise<Requirement> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/${type}s/${resourceId}/requirements`,
			{
				method: 'POST',
				body: JSON.stringify({ documentTypeId, isOptional, graceDays }),
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		if (!response.ok) {
			throw new Error('Failed to add requirement to supplier company resource');
		}
		return await response.json();
	}

	async evaluateCompanySupplierResource(
		companyId: string,
		supplierId: string,
		resourceId: string,
		value: ResourceEvaluationState,
		type: RequirementSubject
	): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/${type}s/${resourceId}/evaluations`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ value }),
			}
		);
		if (!response.ok) {
			throw new Error('Failed to evaluate company supplier resource');
		}
	}

	async autoEvaluateCompanySupplierResource(companyId: string, supplierId: string, resourceId: string, type: RequirementSubject): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/${type}s/${resourceId}/evaluate`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		if (!response.ok) {
			throw new Error('Failed to auto evaluate company supplier resource');
		}
	}

	async getCompanySupplierResourceEvaluations(
		companyId: string,
		supplierId: string,
		resourceId: string,
		type: RequirementSubject,
		pageParam: number,
		sort?: SortMeta,
		filter?: GetResourceEvaluationFilter
	): Promise<Evaluation<ResourceEvaluationState>[]> {
		const query = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...sort,
			...filter,
		}).toString();

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/${type}s/${resourceId}/evaluations?${query}`
		);
		const { results }: GetEvaluationsResponse<ResourceEvaluationState> = await response.json();

		return results.map(mapEvaluation);
	}

	async evaluateCompanySupplierResourceRequirement(
		companyId: string,
		supplierId: string,
		resourceId: string,
		type: RequirementSubject,
		requirementId: string,
		result: ResourceDocumentEvaluationState,
		expiresAt?: Date,
		siteIds?: string[],
		selectAll?: boolean
	): Promise<void> {
		const body = JSON.stringify({
			result,
			sitesIds: siteIds,
			selectAll,
			...(expiresAt && { expiresAt: dateToRFC3339(expiresAt, true) }),
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/${type}s/${resourceId}/documents/${requirementId}/evaluations`,
			{
				method: 'POST',
				body,
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		if (!response.ok) {
			throw new Error('Failed to evaluate company supplier resource requirement');
		}
	}

	async updateSiteSupplierGlobalStatus(companyId: string, siteId: string, supplierId: string, isGlobal: boolean): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${supplierId}/global`,
			{
				method: 'PUT',
				body: JSON.stringify({ isGlobal }),
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		if (!response.ok) {
			throw new Error(`Failed to update site worker global status: ${response.status}`);
		}
		const { hasRequirements = true } = await response.json();
		return hasRequirements;
	}

	async updateCompanySupplierRequirement(
		companyId: string,
		supplierId: string,
		requirementId: string,
		isOptional: boolean,
		graceDays: number
	): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/requirements/${requirementId}`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ isOptional, graceDays }),
			}
		);
		return response.json();
	}

	async updateCompanySupplierRequirements(companyId: string, supplierId: string, requirements: Requirement[]): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/requirements`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ requirements: requirements }),
			}
		);
	}

	async getCompanySupplierRequirements(companyId: string, supplierId: string, subject: RequirementSubject): Promise<Requirement[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/requirements?subject=${subject}`
		);
		if (!response.ok) {
			throw new Error('Failed to get company supplier requirements');
		}
		const { results } = await response.json();
		return results;
	}

	async addCompanySupplierRequirement(
		companyId: string,
		supplierId: string,
		documentTypeId: string,
		isOptional: boolean,
		graceDays: number,
		requirementSubject: RequirementSubject
	): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/requirements/${requirementSubject}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ documentTypeId, isOptional, graceDays }),
			}
		);
	}

	async deleteCompanySupplierRequirement(companyId: string, supplierId: string, requirementId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/requirements/${requirementId}`,
			{
				method: 'DELETE',
			}
		);
	}

	async addCompanySupplierRequirementSpecialization(
		companyId: string,
		supplierId: string,
		requirementId: string,
		specialization: Tag,
		resType: DocumentTypeResourceType
	): Promise<void> {
		let linkedSpecialization = specialization;
		if (linkedSpecialization.id === linkedSpecialization.name) {
			const specializationCreationURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/specializations`;
			const createSpecializationResponse = await this.apiService.fetchWithToken(specializationCreationURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					name: linkedSpecialization.name,
					resourceType: resType,
				}),
			});
			linkedSpecialization = await createSpecializationResponse.json();
		}

		const linkResponse = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/requirements/${requirementId}/specializations/${linkedSpecialization.id}`,
			{
				method: 'PUT',
			}
		);

		if (!linkResponse.ok) {
			return Promise.reject(new Error('cannot link specialization to requirement'));
		}
	}

	async addCompanySupplierRequirementVariant(
		companyId: string,
		supplierId: string,
		requirementId: string,
		variant: Tag,
		resType: DocumentTypeResourceType
	): Promise<void> {
		let linkedVariant = variant;
		if (linkedVariant.id === linkedVariant.name) {
			const variantCreationURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/variants`;
			const createVariantResponse = await this.apiService.fetchWithToken(variantCreationURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					name: linkedVariant.name,
					resourceType: resType,
				}),
			});
			linkedVariant = await createVariantResponse.json();
		}

		const linkResponse = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/requirements/${requirementId}/variants/${linkedVariant.id}`,
			{
				method: 'PUT',
			}
		);

		if (!linkResponse.ok) {
			return Promise.reject(new Error('cannot link variant to requirement'));
		}
	}

	async removeCompanySupplierRequirementSpecialization(
		companyId: string,
		supplierId: string,
		requirementId: string,
		specializationId: string
	): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/requirements/${requirementId}/specializations/${specializationId}`,
			{
				method: 'DELETE',
			}
		);
	}

	async removeCompanySupplierRequirementVariant(companyId: string, supplierId: string, requirementId: string, variantId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/requirements/${requirementId}/variants/${variantId}`,
			{
				method: 'DELETE',
			}
		);
	}

	async linkBadgesResource(
		companyId: string,
		resourceType: ResourceType,
		resourceId: string,
		badgeIds: string[],
		supplierId?: string
	): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId}/${resourceType}s/${resourceId}/badges/link`,
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ badgeIds }),
			}
		);
	}
}

export default ServerSupplierRepository;
