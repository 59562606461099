import { UseAddDocumentType } from "../../../hooks/Document/useAddDocumentType";
import BaseModal from "../../Common/alerts/BaseModal";
import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";
import TextInput from "../../Common/TextInput";
import {
  Box,
  Switch,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Input,
  Flex,
  Tooltip,
  Select, Text
} from "@chakra-ui/react";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import TagList from "../../../screens/Common/TagList";
import DocumentType from "../../../../domain/entities/documentType";
import CellPlusButton from "../../Common/table/CellPlusButton";
import SelectedTagList from "../../Common/SelectedTagList";
import { Identifiable } from "../../../../domain/entities/interfaces/identifiable";
import { Named } from "../../../../domain/entities/interfaces/named";
import Requirement from "../../../../domain/entities/requirement";
import Variant from "../../../../domain/entities/variant";
import lock from "react-useanimations/lib/lock";
import UseAnimations from "react-useanimations";
import DocumentTypeWithPublic from "../../../../domain/entities/documentTypeWithPublic";
import AddVariantsAndSpecializationsToRequirementModal from "./AddVariantsAndSpecializationsToRequirementModal";
import SelectSitesView from "./SelectSitesView";
import Site from "../../../../domain/entities/site";
import { InfoBanner } from "../../Common/alerts/InfoBanner";
import InfiniteTable from "../../Common/table/InfiniteTable";
import { useMediaQuery } from "@chakra-ui/react";
import { FaCheckSquare, FaWindowClose } from "react-icons/fa";
import LoadingView from "../../../screens/Common/LoadingView";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { Alert } from "../../../screens/Common/Alert";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import InputAnimatedSelect from "../../Common/InputAnimatedSelect";
import { BadgeReaderDirection } from "../../../../domain/entities/badgeReaderDirection.enum";

export type DocumentTypeWithRequirement = DocumentType & {
  isOptional: boolean;
  graceDays: number;
  variants: string[];
  specializations: string[];
};

interface AddDocumentTypesModalProps extends UseAddDocumentType {
  onClose: () => void;
  onConfirm: (
    documentTypes: (
      | DocumentType
      | DocumentTypeWithRequirement
      | DocumentTypeWithPublic
    )[],
    siteIds: string[]
  ) => void;
  isRequirement?: boolean;
  isPropagable?: boolean;
  title?: string;
  isFetching?: boolean;
  documentTypesHasNextPage: boolean;
  documentTypesFetchNextPage: () => void;
  requirements?: Requirement[];
  variants?: Variant[];
  specializations?: Variant[];
  propagableSites?: Site[];
  showSiteSelection?: boolean;
  isWorkingSite?: boolean;
  systemDocumentTypesFetchNextPage: () => void;
  systemDocumentTypesHasNextPage: boolean;
  isAddingDocumentsApi?: boolean;
  enableVariantSpecSelection?: boolean;
  showAvailableDocuments?: boolean;
  createVariant?: (variant: Variant) => Promise<Variant>;
  showSourceSelection?: boolean;
}

const initData = {
  isOptional: true,
  graceDays: 0,
  variants: [],
  specializations: [],
};
const AddDocumentTypesModal = ({
  onConfirm,
  onClose,
  isRequirement,
  isPropagable,
  isFetching,
  documentTypes: initialTypes,
  documentTypesHasNextPage,
  documentTypesFetchNextPage,
  getDocumentTypes,
  getSystemDocTypes,
  setGetSystemDocTypes,
  search,
  setSearch,
  tags,
  allTags,
  setTags,
  sort,
  setSort,
  title,
  requirements,
  variants,
  specializations,
  propagableSites,
  showSiteSelection,
  isWorkingSite,
  systemDocumentTypesFetchNextPage,
  systemDocumentTypesHasNextPage,
  isAddingDocumentsApi,
  enableVariantSpecSelection,
  createVariant,
  showAvailableDocuments = true,
  hookDocumentCategory,
  setHookDocumentCategory,
  showSourceSelection
}: AddDocumentTypesModalProps) => {
  const { t } = useTranslation("documents");
  const mapTypes = useMemo(() => {
    if (isRequirement) {
      return initialTypes?.map((documentType) => ({
        ...documentType,
        ...initData,
      }));
    }
    if (isPropagable) {
      return initialTypes?.map((documentType) => ({
        ...documentType,
        isPublic: false,
      }));
    }
    return initialTypes;
  }, [isRequirement, isPropagable, initialTypes]);

  const [documentTypes, setDocumentTypes] = useState<
    DocumentType[] | DocumentTypeWithRequirement[] | DocumentTypeWithPublic[]
  >(mapTypes);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<
    DocumentType[] | DocumentTypeWithRequirement[] | DocumentTypeWithPublic[]
  >([]);
  const [
    editRequirementVariantsAndSpecializations,
    setEditRequirementVariantsAndSpecializations,
  ] = useState<false | DocumentTypeWithRequirement>(false);
  const [showSelectSites, setShowSelectSites] = useState(false);
  const [siteIds, setSiteIds] = useState<string[]>([]);
  const [step, setStep] = useState<number>(0);
  const [showInfoBanner, setShowInfoBanner] = useState<boolean>(false);
  const [changeCategoryReqWarning, setChangeCategoryReqWarning] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<DocumentTypeCategory>();

  const columns = [
    { field: "name", label: t("name", { ns: "common" }) },
    { field: "tags", label: t("tags", { ns: "common" }) },
    ...(showAvailableDocuments && isRequirement
      ? [
        {
          field: "availableDocuments",
          label: t("availableDocuments", { ns: "requirements" }),
        },
      ]
      : []),
    ...(isPropagable
      ? [{ field: "isPublic", label: t("isPublic", { ns: "requirements" }) }]
      : []),
    ...(isRequirement
      ? [{ field: "graceDays", label: t("graceDays", { ns: "requirements" }) }]
      : []),
    ...(isRequirement
      ? [
        {
          field: "isOptional",
          label: t("isOptional", { ns: "requirements" }),
        },
      ]
      : []),
  ];

  const handleIsPublicSwitch = (documentTypeId: string, value: boolean) => {
    setDocumentTypes((prev) =>
      prev.map((prevDocumentType) =>
        prevDocumentType.id === documentTypeId
          ? { ...prevDocumentType, isPublic: value }
          : prevDocumentType
      )
    );
  };

  const handleSwitch = (
    documentType: DocumentTypeWithRequirement,
    value: boolean
  ) => {
    setDocumentTypes((prev) =>
      prev.map((prevDocumentType) =>
        prevDocumentType.id === documentType.id
          ? { ...prevDocumentType, isOptional: value }
          : prevDocumentType
      )
    );
  };

  const handleGraceDays = (
    documentType: DocumentTypeWithRequirement,
    value: number
  ) => {
    setDocumentTypes((prev) =>
      prev.map((prevDocumentType) =>
        prevDocumentType.id === documentType.id
          ? { ...prevDocumentType, graceDays: parseInt(value.toString()) }
          : prevDocumentType
      )
    );
  };

  const handleRemoveElement = (element: Identifiable & Named) => {
    setSelectedDocumentTypes((prev) =>
      prev.filter((prevElement) => prevElement.id !== element.id)
    );
  };

  const handleSelectDocumentType = (
    documentType: DocumentType | DocumentTypeWithRequirement
  ) => {
    // if it's not a requirement there is no need for variant and specs selection
    if (!enableVariantSpecSelection) {
      setSelectedDocumentTypes((prev) => [...prev, documentType]);
      return;
    }

    if (isRequirement && requirements && variants && specializations) {
      if (requirements.some((r) => r.documentType?.id === documentType.id)) {
        // we need to select variants and specialization avoiding those already assigned to the same document type
        setStep(1);
        setEditRequirementVariantsAndSpecializations(
          documentType as DocumentTypeWithRequirement
        );
        return;
      }
    }
    setStep(2);
    setEditRequirementVariantsAndSpecializations(
      documentType as DocumentTypeWithRequirement
    );
    setShowInfoBanner(true);
  };

  useEffect(() => {
    if (isWorkingSite) {
      getSystemDocTypes();
      setGetSystemDocTypes(true);
    } else {
      getDocumentTypes();
    }
  }, []);

  useEffect(() => {
    setDocumentTypes(mapTypes);
  }, [mapTypes]);

  const updateSiteIds = (siteIds: string[]) => {
    setSiteIds(siteIds);
  };

  const excludeSort = new Set(["graceDays", "isOptional", "tags"]);
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const handleSourceSelection = (e?: any ) => {
    const source: DocumentTypeCategory =  e ? e : selectedCategory;
    if(hookDocumentCategory && hookDocumentCategory !== source) setSelectedDocumentTypes([]);
    setHookDocumentCategory(source);
  };

  switch (step) {
    case 1:
      return (
        <AddVariantsAndSpecializationsToRequirementModal
          variants={variants}
          specializations={specializations}
          requirements={requirements}
          selected={
            editRequirementVariantsAndSpecializations as unknown as DocumentTypeWithRequirement
          }
          onClose={() => {
            setStep(0);
            setShowSelectSites(false);
            setEditRequirementVariantsAndSpecializations(false);
          }}
          setRequirements={setSelectedDocumentTypes}
          checkAlreadyCreated={true}
          createVariant={createVariant}
          title={t("addVariantsAndSpecializationsToRequirementModal", {
            ns: "requirements",
          })}
        />
      );
    case 2:
      return (
        <AddVariantsAndSpecializationsToRequirementModal
          variants={variants}
          specializations={specializations}
          requirements={requirements}
          selected={
            editRequirementVariantsAndSpecializations as unknown as DocumentTypeWithRequirement
          }
          createVariant={createVariant}
          onClose={() => {
            setStep(0);
            setShowSelectSites(false);
            setEditRequirementVariantsAndSpecializations(false);
          }}
          setRequirements={setSelectedDocumentTypes}
          checkAlreadyCreated={false}
          title={t("selectVariantsAndSpecializationsForRequirement", {
            ns: "requirements",
          })}
        />
      );
    default:
      return (
        <BaseModal
          title={title ?? t("addDocumentType", { ns: "documents" })}
          onClose={() => onClose()}
          avoidCloseOnOnConfirm={true}
          onConfirmDisabled={
            !selectedDocumentTypes || !(selectedDocumentTypes?.length > 0)
          }
          isLoading={isAddingDocumentsApi}
          onCancel={() => {
            setSearch(null);
            setSort(null);
            setTags([]);
            if (!showSelectSites) {
              onClose();
            } else {
              setShowSelectSites(false);
            }
          }}
          onConfirm={() => {
            setSearch(null);
            setSort(null);
            setTags([]);
            if (!showSelectSites && showSiteSelection) {
              setShowSelectSites(true);
              return;
            }
            onConfirm(selectedDocumentTypes, siteIds);
          }}
          size="5xl"
        >
          {!showSelectSites && (
            <Flex flexDirection={"column"} gap={4}>
              {showSourceSelection &&
                 <InputAnimatedSelect
                   isRequired
                   marginTop={5}
                   id="selectSourceReq"
                   label={t("selectSource", { ns: "requirements" })}
                   selectedValue={hookDocumentCategory}
                   onSelect={(option) => {
                     setSelectedCategory(option as DocumentTypeCategory)
                     if(selectedDocumentTypes?.length > 0 ){
                       setChangeCategoryReqWarning(true)}
                     else {
                       handleSourceSelection(option as DocumentTypeCategory)
                     }
                   }}
                   items={[
                     {id: DocumentTypeCategory.SITE, name: t("siteType", { ns: "requirements" })},
                     {id: DocumentTypeCategory.COMPANY, name: t("companyType", { ns: "requirements" })},
                   ]}
                 />
              }
              <TextInput
                value={search}
                onChange={setSearch}
                placeholder={t("search", { ns: "common" })}
              />
              {selectedDocumentTypes?.length > 0 && showInfoBanner && (
                <InfoBanner
                  text={t("propagateRequirementInfo", { ns: "documents" })}
                />
              )}

              {isWorkingSite && (
                <InfoBanner
                  text={t("deleteRequirementsInfo", { ns: "documents" })}
                />
              )}
              <Box mb={5}>
                <SelectedTagList
                  elements={selectedDocumentTypes}
                  onRemove={handleRemoveElement}
                />
              </Box>

              {isFetching ? (
                <Box h={32}>
                  <LoadingView />{" "}
                </Box>
              ) : (
                <Flex
                  flexDirection={"column"}
                  alignItems={"start"}
                  border="1px solid"
                  borderColor="gray.300"
                  borderRadius="10px"
                  width={isMobile ? "800px" : "100%"}
                  position={isMobile ? undefined : "relative"}
                  overflow={"auto"}
                >
                  <InfiniteTable
                    autosize
                    tableId="document-types-table-add-modal"
                    bottomThreshold={250}
                    infiniteScroll={{
                      dataLength: documentTypes.length,
                      hasNextPage: isWorkingSite
                        ? systemDocumentTypesHasNextPage
                        : documentTypesHasNextPage,
                      fetchNextPage: isWorkingSite
                        ? systemDocumentTypesFetchNextPage
                        : documentTypesFetchNextPage,
                    }}
                    showEmptyText={
                      documentTypes.filter(
                        (dt) =>
                          !selectedDocumentTypes?.some((sdt) => sdt.id === dt.id)
                      ).length === 0
                    }
                    emptyText={t("noDocumentTypes", { ns: "documents" })}
                  >
                    <Thead>
                      <Tr>
                        {columns.map((column) => (
                          <Th key={column.field}>
                            <TableColumnHeader
                              text={column.label}
                              filter={
                                column.field === "tags"
                                  ? {
                                    isActive: tags.length > 0,
                                    component: (
                                      <ColumnFilterComponent
                                        tags={allTags}
                                        value={tags}
                                        type="tags"
                                        updateFilter={(value) => {
                                          setTags(value as string[]);
                                        }}
                                      />
                                    ),
                                  }
                                  : undefined
                              }
                              sort={
                                excludeSort.has(column.field)
                                  ? undefined
                                  : {
                                    handler: (direction) =>
                                      setSort({
                                        field: column.field,
                                        direction,
                                      }),
                                    direction:
                                      sort?.field === column.field
                                        ? sort.direction
                                        : null,
                                  }
                              }
                            />
                          </Th>
                        ))}
                        <Th width={100} />
                      </Tr>
                    </Thead>
                    <Tbody>
                      {documentTypes
                        .filter(
                          (dt) =>
                            !selectedDocumentTypes?.some(
                              (sdt) => sdt.id === dt.id
                            )
                        )
                        .map((documentType) => (
                          <Tr key={documentType?.id}>
                            <Tooltip
                              label={documentType?.description}
                              placement="bottom-start"
                              openDelay={500}
                            >
                              <Td>{documentType.name}</Td>
                            </Tooltip>
                            <Td>
                              <TagList tags={documentType.tags} />
                            </Td>
                            {showAvailableDocuments && isRequirement && (
                              <Td textAlign="center">
                                {" "}
                                {documentType.filesCount > 0 ? (
                                  <FaCheckSquare color="green" />
                                ) : (
                                  <FaWindowClose color="red" />
                                )}
                              </Td>
                            )}
                            {isPropagable && (
                              <Td paddingLeft={5}>
                                <UseAnimations
                                  animation={lock}
                                  size={36}
                                  autoplay={false}
                                  onClick={() => {
                                    handleIsPublicSwitch(
                                      documentType.id,
                                      !documentType["isPublic"]
                                    );
                                  }}
                                  reverse={documentType["isPublic"]}
                                />
                              </Td>
                            )}
                            {isRequirement && (
                              <Td>
                                <Input
                                  type="number"
                                  defaultValue={documentType["graceDays"]}
                                  placeholder={t("insertHereGraceDays", {
                                    ns: "requirements",
                                  })}
                                  size="sm"
                                  onBlur={(e) =>
                                    handleGraceDays(
                                      documentType as DocumentTypeWithRequirement,
                                      e.target.value as unknown as number
                                    )
                                  }
                                  isDisabled={isWorkingSite}
                                />
                              </Td>
                            )}
                            {isRequirement && (
                              <Td>
                                <Switch
                                  isChecked={!documentType["isOptional"]}
                                  onChange={(e) =>
                                    handleSwitch(
                                      documentType as DocumentTypeWithRequirement,
                                      !e.target.checked
                                    )
                                  }
                                  isDisabled={isWorkingSite}
                                />
                              </Td>
                            )}
                            <Td width={100}>
                              <CellPlusButton
                                onClick={() =>
                                  handleSelectDocumentType(documentType)
                                }
                              />
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </InfiniteTable>
                </Flex>
              )}
            </Flex>
          )}
          {showSelectSites && (
            <SelectSitesView
              autosize
              title={t("selectSitesCreateRequirement", { ns: "requirements" })}
              reminderText={t("noSiteSelected", { ns: "common" })}
              alertText={t("siteSelectionAlert", { ns: "common" })}
              siteSelectedAction={(siteIds) => {
                return updateSiteIds(siteIds);
              }}
              showSelectAll={false}
              siteList={propagableSites}
              includeFooterButton={false}
            />
          )}
          {changeCategoryReqWarning &&
            <ConfirmAlert
              variant={'warning'} title={t('warning', {ns: 'common'})}
              message={t('changeCategoryReq', {ns: 'requirements'})}
              onConfirm={()=>(handleSourceSelection(), setChangeCategoryReqWarning(false))}
              onCancel={()=>(setChangeCategoryReqWarning(false))}/>
          }
        </BaseModal>
      );
  }
};

export default AddDocumentTypesModal;
