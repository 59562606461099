import Tag from "../../../domain/entities/tag";
import Document from "../../../domain/entities/document";
import DocumentType from "../../../domain/entities/documentType";
import { makeGetTags } from "../../../core/app/factory/tags/usecases";
import {
  makeGetDocumentTypes,
  makeGetDocumentTypesForAi,
} from "../../../core/app/factory/documentTypes/usecases";
import {
  makeAddFileToDocument,
  makeConfirmAiTask,
  makeCreateAiTask,
  makeDeleteAiTask,
  makeDeleteDocument,
  makeDeleteFileFromDocument,
  makeGetAiTaskDetail,
  makeGetAiTasks,
  makeGetAiTaskSites,
  makeGetDocumentFiles,
  makeGetDocumentFilesLog,
  makeGetDocumentLog,
  makeUpdateDocument,
  makeUpdateFile,
} from "../../../core/app/factory/documents/usecases";
import File from "../../../domain/entities/file";
import {
  DocumentActionLogEntry,
  DocumentFileLogEntry,
} from "../../../domain/entities/documentLogEntry";
import {
  makeCreateSiteDocument,
  makeGetSiteDocuments,
} from "../../../core/app/factory/sites/usecases";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import {
  GetDocumentFilesFilter,
  GetDocumentLogFilter,
  GetDocumentsFilter,
} from "../../../domain/repositories/documentRepository";
import { DocumentsViewModel } from "../../hooks/Document/useDocuments";
import { AddDocumentTypeViewModel } from "../../hooks/Document/useAddDocumentType";
import { DocumentInfoViewModel } from "../../hooks/Document/useDocumentInfo";
import DocumentTypeWithPublic from "../../../domain/entities/documentTypeWithPublic";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";
import { DocumentSites } from "../../../domain/entities/site";
import FileEntity from "../../../domain/entities/file";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import AiTask from "../../../domain/entities/aiTask";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import AiTaskDocument from "../../../domain/entities/aiTaskDocument";

class SiteGeneralDocumentsViewModel
  implements
  DocumentsViewModel,
  AddDocumentTypeViewModel,
  DocumentInfoViewModel {
  private getGeneralDocumentsUseCase = makeGetSiteDocuments();
  private deleteDocumentUseCase = makeDeleteDocument();
  private createDocumentUseCase = makeCreateSiteDocument();
  private updateDocumentUseCase = makeUpdateDocument();
  private getTagsUseCase = makeGetTags();
  private _getDocumentTypes = makeGetDocumentTypes();
  private getDocumentTypesForAiUseCase = makeGetDocumentTypesForAi();

  constructor(private siteId: string) {}

  async getDocuments(
    companyId: string,
    filter?: GetDocumentsFilter,
    sort?: SortMeta,
    pageParam?: number
  ): Promise<Document[]> {
    return await this.getGeneralDocumentsUseCase.Call({
      companyId,
      siteId: this.siteId,
      sort,
      filter,
      pageParam,
    });
  }

  async getTags(companyId: string, search?: string): Promise<Tag[]> {
    return await this.getTagsUseCase.Call({ companyId, search });
  }

  async getDocumentTypes(
    companyId: string,
    search?: string,
    tags?: string[],
    sort?: SortMeta,
    pageParam?: number
  ): Promise<DocumentType[]> {
    return await this._getDocumentTypes.Call({
      companyId,
      filter: DocumentTypeCategory.SITE,
      includePublic: true,
      search,
      tags,
      sort,
      pageParam,
    });
  }

  async createDocument(companyId: string, documents: string[]): Promise<void> {
    return await this.createDocumentUseCase.Call({
      companyId,
      siteId: this.siteId,
      documents,
    });
  }

  async createPropagableDocuments(
    companyId: string,
    documents: DocumentTypeWithPublic[]
  ): Promise<void> {
    throw new Error("Method not implemented.");
  }

  async updateDocument(companyId: string, document: Document): Promise<void> {
    return await this.updateDocumentUseCase.Call({
      companyId,
      document,
      siteId: this.siteId,
    });
  }

  evaluateDocument(
    companyId: string,
    documentId: string,
    evaluationState?: ResourceDocumentEvaluationState,
    expirationDate?: Date
  ) {
    throw new Error("Method not implemented.");
  }

  async deleteDocument(
    companyId: string,
    documentId: string,
    siteId?: string
  ): Promise<Document[]> {
    return await this.deleteDocumentUseCase.Call({
      companyId,
      documentId,
      siteId,
    });
  }

  async getDocumentFiles(
    companyId: string,
    documentId: string,
    filter?: GetDocumentFilesFilter,
    sort?: SortMeta,
    siteId?: string
  ): Promise<File[]> {
    return await makeGetDocumentFiles().Call({
      companyId,
      documentId,
      filter,
      sort,
      siteId,
    });
  }

  async getDocumentFilesLogs(
    companyId: string,
    documentId: string,
    filter?: GetDocumentLogFilter,
    sort?: SortMeta,
    pageParam?: number
  ): Promise<DocumentActionLogEntry[]> {
    return await makeGetDocumentFilesLog().Call({
      companyId,
      documentId,
      filter,
      sort,
      pageParam,
    });
  }

  async deleteDocumentFile(
    companyId: string,
    documentId: string,
    fileId: string,
    siteId?: string
  ): Promise<void> {
    await makeDeleteFileFromDocument().Call({
      companyId,
      documentId,
      fileId,
      siteId,
    });
  }

  async addFileToDocument(
    companyId: string,
    documentId: string,
    file: File,
    siteId: string
  ): Promise<void> {
    await makeAddFileToDocument().Call({
      companyId,
      documentId,
      file,
      siteId,
    });
  }

  async getDocumentLogs(
    companyId: string,
    documentId: string,
    filter?: GetDocumentLogFilter,
    sort?: SortMeta,
    resourceId?: string,
    siteId?: string,
    target?: string,
    pageParam?: number
  ): Promise<DocumentFileLogEntry[]> {
    return await makeGetDocumentLog().Call({
      companyId,
      documentId,
      filter,
      sort,
      siteId: this.siteId,
      pageParam,
    });
  }

  async updateFile(
    companyId: string,
    documentId: string,
    fileId: string,
    updatedFiles: Partial<FileEntity>[],
    siteIds?: string[],
    target?: string,
    resourceId?: string,
    selectAll?: boolean
  ): Promise<void> {
    return await makeUpdateFile().Call({
      companyId,
      documentId,
      updatedFiles,
      fileId,
      siteIds,
      target,
      resourceId,
      selectAll,
    });
  }
  getDocumentSites(
    companyId: string,
    documentId: string
  ): Promise<DocumentSites> {
    throw new Error("Method not implemented.");
  }

  // AI section fuctions

  async createAiTask(
    companyId: string,
    files: FileEntity,
    sectionCode: AiTaskSectionCode,
    resourceId: string,
    type: DocumentTypeCategory
  ): Promise<string> {
    return await makeCreateAiTask().Call({
      companyId,
      files,
      sectionCode,
      resourceId,
      resourceType: type as unknown as DocumentTypeResourceType,
      siteId: this.siteId,
    });
  }

  async getAiTasks(
    companyId: string,
    sectionCode: AiTaskSectionCode,
    resourceType?: DocumentTypeCategory,
    resourceId?: string,
    siteId?: string
  ): Promise<AiTask[]> {
    return await makeGetAiTasks().Call({
      companyId,
      sectionCode,
      resourceType,
      resourceId,
      siteId,
    });
  }

  async getAiTaskDetail(companyId: string, taskId: string): Promise<AiTask> {
    return await makeGetAiTaskDetail().Call({ companyId, taskId });
  }

  async deleteAiTask(companyId: string, taskId: string): Promise<void> {
    return await makeDeleteAiTask().Call({ companyId, taskId });
  }

  async sendTaskConfirmation(
    companyId: string,
    taskId: string,
    aiTaskDocuments: AiTaskDocument[],
    siteIds: string[]
  ): Promise<void> {
    return await makeConfirmAiTask().Call({
      companyId,
      taskId,
      aiTaskDocuments,
      siteIds,
    });
  }

  async getAiTaskSites(
    companyId: string,
    sectionCode: AiTaskSectionCode,
    taskId: string,
    sortSites: SortMeta,
    filterSites: GetSitesFilter,
    pageParam?: number
  ) {
    return await makeGetAiTaskSites().Call({
      companyId,
      sectionCode,
      taskId,
      sortSites,
      filterSites,
      pageParam,
    });
  }

  async getDocumentTypesForAi(
    companyId: string,
    aiTaskId: string,
    resourceType: DocumentTypeCategory
  ): Promise<DocumentType[]> {
    return await this.getDocumentTypesForAiUseCase.Call({
      companyId,
      aiTaskId,
      resourceType,
    });
  }
}

export { SiteGeneralDocumentsViewModel };
