import { SiteWorkerParams } from "../../../domain/entities/siteWorker";
import { SiteResourceDetailViewModel } from "../../hooks/Site/useSiteResourceDetailViewModel";
import SiteWorkerDetailViewModel from "../sites/SiteWorkerDetailViewModel";
import {
  makeAddRequirementToSiteWorker,
  makeDeleteSiteWorker,
} from "../../../core/app/factory/siteWorkers/usecases";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";
import { makeEvaluateSiteDocument } from "../../../core/app/factory/documents/usecases";

class WorkingSiteWorkerDetailViewModel
  extends SiteWorkerDetailViewModel
  implements SiteResourceDetailViewModel<SiteWorkerParams> {
  evaluateSiteResource = undefined;
  async addRequirementToSiteResource(
    companyId: string,
    workerId: string,
    siteId: string,
    requirements: {
      documentTypeId: string;
      isOptional: boolean;
      graceDays: number;
    }[]
  ): Promise<void> {
    await makeAddRequirementToSiteWorker().Call({
      companyId,
      workerId,
      siteId,
      requirements
    });
  }

  async evaluateDocument(
    companyId: string,
    siteId: string,
    documentId: string,
    result: ResourceDocumentEvaluationState,
    expiresAt?: Date,
    noEvaluationExpiration?: boolean,
    target?: string,
    resourceId?: string,
  ): Promise<void> {
    await makeEvaluateSiteDocument().Call({
      companyId,
      siteId,
      documentId,
      result,
      expiresAt,
      noEvaluationExpiration,
      target,
      resourceId,
    });
  }

  async deleteSiteResource(
    companyId: string,
    workerId: string,
    siteId: string,
  ): Promise<void> {
    await makeDeleteSiteWorker().Call({ companyId, workerId, siteId });
  }
}

export default WorkingSiteWorkerDetailViewModel;
